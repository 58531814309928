import { createElement } from 'preact'
import { useEffect } from 'preact/hooks'

/**
 * @typedef { import('preact').ComponentClass } ComponentClass
 * @typedef { import('preact').FunctionComponent } FunctionComponent
 */

/**
 * @typedef {Object} WakeLockSentinel
 * @property {() => void} release
 * @property {boolean} [released] - Available since Chromium 87 (https://www.chromestatus.com/feature/5632527123349504)
 * @property {(name: string, callback: function, options?: Object) => void } addEventListener
 */


/** @type {boolean} */
const isWakeLockSupported = 'wakeLock' in navigator

/**
 * Wake lock boundary
 * @note Not using passing state to wrapped component as there is no use case
 * @see https://web.dev/wakelock/
 * @see https://developer.mozilla.org/en-US/docs/Web/API/WakeLock
 */
export function WakeLock({
  children
}) {
  // Opaque when not supported
  if (!isWakeLockSupported) {
    return children
  }

  // On mount/ unmount
  useEffect(() => {
    /** @type {WakeLockSentinel|null} */
    let wakeLock = null

    requestWakeLock()

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      releaseWakeLock()
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }

    /**
     * Request new wake lock when visibile
     */
    function handleVisibilityChange () {
      if (document.visibilityState === 'visible') {
        requestWakeLock()
      }
    }

    /**
     * Request wake lock
     * @return {Promise}
     */
    async function requestWakeLock() {
      // Release previous wake lock
      releaseWakeLock()

      try {
        // @ts-ignore
        // eslint-disable-next-line compat/compat
        wakeLock = await navigator.wakeLock.request('screen') // Note: Type is optional since Chromium 89 (https://www.chromestatus.com/feature/5725750881681408)
      } catch (error) {
        // No-op, probably low battery
        return
      }

      // Reset wake lock sentinel on release (on document.visibilityState: hidden)
      wakeLock.addEventListener('release', () => wakeLock = null, { once: true })
    }

    /**
     * Release wake lock
     */
    function releaseWakeLock() {
      if (wakeLock) {
        wakeLock.release()
      }
    }
  }, [])

  return children
}

/**
 * Wake lock Higher-Order Component
 * @param {ComponentClass | FunctionComponent} WrappedComponent
 * @return {FunctionComponent}
 */
export function withWakeLock(
  WrappedComponent
) {
  const componentDisplayName = WrappedComponent.displayName || WrappedComponent.name || 'unknown'

  /**
   * @param {Object} props
   */
  const Wrapped = props =>
    createElement(
      WakeLock,
      null,
      createElement(
        WrappedComponent,
        props
      )
    )

  Wrapped.displayName = `withWakeLock(${componentDisplayName})`

  return Wrapped
}
