/** @typedef {string} Overlay */

/**
 * Overlays
 * @enum {Overlay}
 */
export default {
  GUIDELINES: 'guidelines',
  PIXELATE: 'pixelate',
  FULLSCREEN: 'fullscreen',
}
