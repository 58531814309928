import { html } from 'htm/preact'

/**
 * SVG filter
 * - Pixelate based on [this](https://codepen.io/AmeliaBR/pen/QEmBko) and [this](https://codesandbox.io/s/km3opvn6yv?file=/src/index.js)
 * @param {Object} props
 * @param {number} [props.size]
 */
export default function SvgFilters({
  // Mosaic size
  size = 10
}) {
  return html`
    <svg
      width="0"
      height="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter
          id="bip-filter-pixelate"
          x="0"
          y="0"
        >
          <!-- Create cotout of 2x2 pixels inside 10x10 mesh -->
          <feFlood
            x=${size / 2 - 1}
            y=${size / 2 - 1}
            height=${size / 5}
            width=${size / 5}
          />
          <feComposite
            width=${size}
            height=${size}
          />
          <feTile result="a" />

          <!-- Mix source graphic and tiled mosaic -->
          <feComposite
            in="SourceGraphic"
            in2="a"
            operator="in"
          />

          <!-- Expand -->
          <feMorphology
            operator="dilate"
            radius=${Math.round(size / 2) - 1}
          />
        </filter>
      </defs>
    </svg>
  `
}
