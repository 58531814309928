import { openDB } from 'idb'

import { MINUTES } from '../../../Constants/DateTime.js'

/**
 * @typedef { import('idb').IDBPDatabase } IDBPDatabase
 * @typedef { import('../../../Utils/Api').AirlyApi } AirlyApi
 */

/**
 * Cache data wrapper
 * @typedef {Object} CacheItem
 * @property {ApiMeasurementPayload} data - Reponse payload
 * @property {number} id - Station Id
 * @property {number} receivedAt - Fetched at
 */

/**
 * Response for 'Get measurements for installation'
 * @typedef {Object} ApiMeasurementPayload
 * @property {ApiMeasurement} current
 * @property {ApiMeasurement[]} history
 * @property {ApiMeasurement[]} forecast
 */

/**
 * @typedef {Object} ApiMeasurement - Single measurement
 * @property {string} fromDateTime - Measurement Date in ISO8601
 * @property {string} tillDateTime - Measurement valid till Date in ISO8601
 * @property {ApiMeasurementValue[]} values
 * @property {ApiMeasurementIndex[]} indexes
 * @property {ApiMeasurementStandard[]} standards
 */

/**
 * @typedef {Object} ApiMeasurementValue
 * @property {string} name - PM1 | PM25 | PM10 | PRESSURE | HUMIDITY | TEMPERATURE
 * @property {number} value
 */

/**
 * @typedef {Object} ApiMeasurementIndex
 * @property {string} name
 * @property {number} value
 * @property {ApiIndexLevel} level
 * @property {string} description
 * @property {string} advice
 * @property {string} color
 *
 * @example
 * ```json
 * {
 *   "name": "AIRLY_CAQI",
 *   "value": 4.75,
 *   "level": "VERY_LOW",
 *   "description": "Great air here today!",
 *   "advice": "Zero dust - zero worries!",
 *   "color": "#6BC926"
 * }
 * ```
 */

/**
 * @typedef {'VERY_LOW'|'LOW'|'MEDIUM'|'HIGH'|'VERY_HIGH'|'EXTREME'|'AIRMAGEDDON'} ApiIndexLevel
 */

/**
 * @typedef {Object} ApiMeasurementStandard
 * @property {string} name
 * @property {string} pollutant
 * @property {number} limit
 * @property {number} percent
 * @property {string} averaging
 *
 * @example
 * ```json
 * {
 *   "name": "WHO",
 *   "pollutant": "PM25",
 *   "limit": 15.00,
 *   "percent": 19.00,
 *   "averaging": "24h"
 * }
 * ```
 */

/**
 * @typedef {Object} Measurement - Modified measurement
 * @property {string} label
 * @property {ApiIndexLevel} caqiIndexLevel
 * @property {Array<{ name: string, value: number, label: string | undefined, unit: string | undefined }>} items
 */

/**
 * @typedef {Object} Config
 * @property {string} cacheDbName
 * @property {number} measurementInterval
 */

export default class Service {
  /**
   * Constructor
   * @param {AirlyApi} airlyApi
   * @param {Partial<Config>} config
   */
  constructor (airlyApi, config) {
    /**
     * Airly API
     * @access protected
     * @type {AirlyApi}
     */
    this.airlyApi = airlyApi

    /**
     * Cache database
     * @access protected
     * @type {IDBPDatabase|undefined}
     */
    this.cacheDb = undefined

    /**
     * Config
     * @access protected
     * @type {Config}
     */
    this.config = {
      cacheDbName: '@biotic/presenter-client/renderer/airly-station',
      measurementInterval: 15 * MINUTES,
      ...config,
    }

    /** @type {Map<string, { label: string, unit: string }>} */
    this.valueDescriptors = new Map([
      // ['PM1', { label: 'PM1', unit: 'µg/m³' }],
      ['PM25', { label: 'PM2,5', unit: 'µg/m³' }],
      ['PM10', { label: 'PM10', unit: 'µg/m³' }],
      ['PRESSURE', { label: 'Ciśn', unit: 'hPa' }],
      ['HUMIDITY', { label: 'Wilg.', unit: '%' }],
      ['TEMPERATURE', { label: 'Temp.', unit: '°C' }],
    ])
  }

  /**
   * Initialize async stuff
   * @access public
   * @return {Promise<this>}
   */
  async initialize() {
    this.cacheDb = await openDB(this.config.cacheDbName, 1, {
      upgrade(db) {
        db.createObjectStore('keyval', { keyPath: 'id' })
          .createIndex('receivedAt', 'receivedAt')

        db.createObjectStore('measurement', { keyPath: 'id' })
          .createIndex('receivedAt', 'receivedAt')
      }
    })

    return this
  }

  /**
   * Get measurements refresh interval
   * @access public
   * @return {number}
   */
  getMeasurementsRefreshInterval() {
    return this.config.measurementInterval
  }

  /**
   * Get reading from cache in not stale or fetch
   * @access public
   * @param {BP.App.Renderer.AirlyStation.Options} stationOptions
   * @param {AbortSignal} [abortSignal]
   * @return {Promise<Measurement>}
   */
  async getMeasurement(stationOptions, abortSignal = undefined) {
    const measurement = await this.getCachedMeasurement(stationOptions)

    return measurement || this.fetchMeasurement(stationOptions, abortSignal)
  }

  /**
   * Get measurements saving result in cache
   * @access protected
   * @param {BP.App.Renderer.AirlyStation.Options} stationOptions
   * @param {AbortSignal} [abortSignal]
   * @return {Promise<Measurement>}
   * @throws {Error}
   */
  async fetchMeasurement(stationOptions, abortSignal = undefined) {
    /** @type {ApiMeasurementPayload} */
    const measurementPayload = await this.airlyApi.get(
      `v2/measurements/installation?${new URLSearchParams({
        includeWind: 'false',
        indexPollutant: 'PM',
        indexType: 'AIRLY_CAQI',
        installationId: stationOptions.id.toString(),
        standardType: 'WHO',
      })}`,
      abortSignal,
      stationOptions.apiKey
    )

    /** @type {CacheItem} */
    const measurementPayloadItem = {
      id: stationOptions.id,
      data: measurementPayload,
      receivedAt: Date.now(),
    }

    // Put in cache
    await this.cacheDb.put('measurement', measurementPayloadItem)

    return this.decorateMeasurement(measurementPayload, stationOptions)
  }

  /**
   * Get cached measurement
   * @access public
   * @param {BP.App.Renderer.AirlyStation.Options} stationOptions
   * @param {boolean} [skipStaleCheck]
   * @param {ApiMeasurementPayload} [data] - Fallback
   * @return {Promise<Measurement|undefined>}
   */
  async getCachedMeasurement(stationOptions, skipStaleCheck = false, data = undefined) {
    // Load from cache
    /** @type {CacheItem|undefined} */
    const dataWrapper = await this.cacheDb.get('measurement', stationOptions.id)

    if (
      dataWrapper &&
      (skipStaleCheck || !Service.isStale(dataWrapper.receivedAt, this.config.measurementInterval))
    ) {
      data = dataWrapper.data
    }

    // Decorate object
    return data
      ? this.decorateMeasurement(data, stationOptions)
      : undefined
  }

  /**
   * Populate cache items
   * @access public
   * @param {{items: BP.App.Renderer.AirlyStation.Options[]}} stationsOptions
   * @return {Promise<void>}
   */
  async populateCacheItems(stationsOptions) {
    await Promise.all(stationsOptions.items.map(stationOptions =>
      this.getMeasurement(stationOptions)
    ))
  }

  /**
   * Populate cache item
   * @access public
   * @param {BP.App.Renderer.AirlyStation.Options} stationOptions
   * @return {Promise}
   */
  async populateCacheItem(stationOptions) {
    await this.populateCacheItems({
      items: [stationOptions]
    })
  }

  /**
   * Clean up cache
   * @access public
   * @return {Promise<void>}
   */
  async purgeCache() {
    const interval = this.config.measurementInterval

    /** @type {CacheItem[]} */
    const cacheItems = await this.cacheDb.getAll('measurement')

    const purgePromises = cacheItems
      // Check if stale
      .filter(cacheItem => Service.isStale(cacheItem.receivedAt, interval))
      // Delete
      .map(cacheItem => this.cacheDb.delete('measurement', cacheItem.id))

    await Promise.all(purgePromises)
  }

  /**
   * Check if data is stale
   * @access protected
   * @param {number} receivedAt
   * @param {number} maxAge
   * @return {boolean}
   */
  static isStale(receivedAt, maxAge) {
    return (
      maxAge !== Infinity &&
      Date.now() > receivedAt + maxAge
    )
  }

  /**
   * Decorate installation measurement
   * @access protected
   * @param {ApiMeasurementPayload} measurementPayload
   * @param {BP.App.Renderer.AirlyStation.Options} stationOptions
   * @return {Measurement}
   */
  decorateMeasurement(measurementPayload, stationOptions) {
    const { current } = measurementPayload

    const label = stationOptions.label || '-'
    const caqiIndex = current.indexes.find(index => index.name === 'AIRLY_CAQI')

    const missingValueDescriptor = { label: undefined, unit: undefined }
    const valueDescriptorKeys = [...this.valueDescriptors.keys()]

    const items = current.values
      // Keep only whitelisted sensors
      .filter(apiMeasurementValue => this.valueDescriptors.has(apiMeasurementValue.name))
      // Sort
      .sort((apiMeasurementValueA, apiMeasurementValueB) =>
        valueDescriptorKeys.indexOf(apiMeasurementValueA.name) -
        valueDescriptorKeys.indexOf(apiMeasurementValueB.name)
      )
      // Decorate values with label and unit
      .map(apiMeasurementValue => ({
        ...apiMeasurementValue,
        ...this.valueDescriptors.get(apiMeasurementValue.name) || missingValueDescriptor,
      }))

    return {
      label,
      caqiIndexLevel: caqiIndex.level,
      items,
    }
  }
}
