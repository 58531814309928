import { getFromPathname as getScreenIdFromPathname } from './screenId.js'
import { getFromUrlSearchParams as getScreenConfigFromUrlSearchParams } from './screenConfig.js'

/**
 * Compare objects
 * @param {any} itemA
 * @param {any} itemB
 * @return {boolean}
 */
export function compare(itemA, itemB) {
  if (itemA === itemB) {
    return true
  }

  return JSON.stringify(itemA) === JSON.stringify(itemB)
}

/**
 * Compare URL config to current one
 * @param {URL} compareUrl
 * @param {string} screenId
 * @param {Object} screenConfig
 * @param {string} startPath
 * @return {boolean}
 */
export function compareUrlConfig(compareUrl, screenId, screenConfig, startPath) {
  // Compare screenId
  const compareScreenId = getScreenIdFromPathname(compareUrl.pathname, startPath)

  if (!compare(compareScreenId, screenId)) {
    return false
  }

  // Compare screenConfig
  const compareScreenConfig = getScreenConfigFromUrlSearchParams(compareUrl.searchParams)

  if (
    compareScreenConfig.resolution !== undefined &&
    !compare(compareScreenConfig.resolution, screenConfig.resolution)
  ) {
    return false
  }

  if (
    compareScreenConfig.isLed !== undefined &&
    !compare(compareScreenConfig.isLed, screenConfig.isLed)
  ) {
    return false
  }

  return true
}
