import { match as pathMatch } from 'path-to-regexp'

/**
 * @typedef { import('../db.js').IDBPDatabase } IDBPDatabase
 */

/**
 * Get screen ID from location + DB fallback
 * @param {Location|URL} location
 * @param {string} startPath
 * @param {IDBPDatabase} db
 * @return {Promise<string|null>}
 */
export async function update(location, startPath, db) {
  // Read from location
  let screenId = getFromPathname(location.pathname, startPath)

  // Fall back to DB
  if (!screenId) {
    screenId = await getFromDb(db)
  } else {
    // Update db
    await setInDb(screenId, db)
  }

  return screenId
}

/**
 * Get from path name
 * @param {string} pathname
 * @param {string} startPath
 * @return {string|null}
 */
export function getFromPathname(pathname, startPath) {
  /** @type {Function} */
  const startPathMatchFn = pathMatch(startPath)

  /** @type {Object|false} */
  const startPathMatch = startPathMatchFn(pathname)

  if (startPathMatch) {
    return startPathMatch.params.screenId
  }

  return null
}

/**
 * Get from DB
 * @param {IDBPDatabase} db
 * @return {Promise<string|null>}
 */
async function getFromDb(db) {
  const screenId = await db.get('state', 'screenId')

  return screenId || null
}

/**
 * Set in DB
 * @param {string} screenId
 * @param {IDBPDatabase} db
 * @return {Promise<void>}
 */
export async function setInDb(screenId, db) {
  await db.put('state', screenId, 'screenId')
}
