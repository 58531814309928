/**
 * @typedef { import('./Service.js').BiValueObject } BiValueObject
 */

/**
 * Get air quality indicator class name
 * @param {number|undefined} aqiPl
 * @return {string}
 */
export function getIndicatorClassName(aqiPl) {
  switch (aqiPl) {
    // Good
    case 0:
      return 'aqi-pl--1-very-good'
    // Satisfactory
    case 1:
      return 'aqi-pl--2-good'
    // Moderate
    case 2:
      return 'aqi-pl--3-moderate'
    // Poor
    case 3:
      return 'aqi-pl--4-satisfactory'
    // Very poor
    case 4:
      return 'aqi-pl--5-bad'
    // Severe
    case 5:
      return 'aqi-pl--6-hazardous'
    // Unknown
    case undefined:
    default:
      return 'aqi-pl--x-unknown'
  }
}

/**
 * Get air quality indicator label
 * @param {number|null|undefined} aqiPl
 * @return {string}
 */
export function getIndicatorDisplayLabel(aqiPl) {
  switch (aqiPl) {
    // Good
    case 0:
      return 'B.dobry'
    // Satisfactory
    case 1:
      return 'Dobry'
    // Moderate
    case 2:
      return 'Umiarkowany'
    // Poor
    case 3:
      return 'Dostateczny'
    // Very poor
    case 4:
      return 'Zły'
    // Severe
    case 5:
      return 'Bardzo zły'
    // Unknown
    case undefined: // No data
    case null: // No data from API
    default: // Out of bounds
      return 'Brak danych'
  }
}
