/**
 * @typedef { import('./Service.js').Measurement } Measurement
 */

/**
 * Get current norm indicator class name
 * @param {Measurement} measurement
 * @return {string}
 */
export function getIndicatorClassName(measurement) {
  switch (measurement.caqiIndexLevel) {
    case 'VERY_LOW':    return 'airly-caqi-level--very-low'
    case 'LOW':         return 'airly-caqi-level--low'
    case 'MEDIUM':      return 'airly-caqi-level--medium'
    case 'HIGH':        return 'airly-caqi-level--high'
    case 'VERY_HIGH':   return 'airly-caqi-level--very-high'
    case 'EXTREME':     return 'airly-caqi-level--extreme'
    case 'AIRMAGEDDON': return 'airly-caqi-level--airmageddon'
    default:            return 'airly-caqi-level--unknown'
  }
}

/**
 * Get current norm indicator level
 * @deprecated NOT USED ATM, may use in a table layout
 * @param {Measurement} measurement
 * @return {string}
 */
export function getIndicatorDisplayLabel(measurement) {
  switch (measurement.caqiIndexLevel) {
    case 'VERY_LOW':    return 'B.dobry'
    case 'LOW':         return 'Dobry'
    case 'MEDIUM':      return 'Umiarkowany'
    case 'HIGH':        return 'Dostateczny'
    case 'VERY_HIGH':   return 'Zły'
    case 'EXTREME':     return 'Bardzo zły'
    case 'AIRMAGEDDON': return 'Airmageddon'
    default:            return 'Brak danych'
  }
}
