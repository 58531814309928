/**
 * @type {Object<string, BP.App.Content.Type>}
 * xtype {{[key: string]: BP.App.Content.Type}}
 * xtype {BP.App.Content.TypeEnum}
 * xenum {BP.App.Content.Type}
 */
const CONTENT_TYPE = {
  AIRLY_STATION: 'airly-station',
  BI_STATION: 'bi-station',
  BI_STATIONS: 'bi-stations',
  CLOCK: 'clock',
  COUNTDOWN: 'countdown',
  FALLBACK: 'fallback',
  IMAGE: 'image',
  REFERENCE: 'reference',
  SCREENSAVER: 'screensaver',
  SYNGEOS_STATION: 'syngeos-station',
  SYNGEOS_STATIONS: 'syngeos-stations',
  TEXT: 'text',
  VIDEO: 'video',
  VIDEO_PLAYLIST: 'video-playlist',
  WEBSITE: 'website',
  YOUTUBE_PLAYLIST: 'youtube-playlist',
}

export default CONTENT_TYPE
