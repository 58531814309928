import { Component } from 'preact'
import { html } from 'htm/preact'

import { MINUTES } from '../../Constants/DateTime.js'

import { noop as onTaskNoop } from '../Progress.js'
import { SyngeosApi } from '../../Utils/Api/index.js'

import {
  getIndicatorClassName,
  getIndicatorDisplayLabel,
} from './SyngeosStation/helpers.js'

import './Station/Stations.css'
import './Station/indicator.css'
import './SyngeosStation/indicator-norm.css'

/**
 * @typedef { import('./SyngeosStation/Service.js').default } Service
 * @typedef { import('./SyngeosStation/Service.js').Readings } Readings
 */

/**
 * @typedef {Object} ComponentProps
 * @property {BP.App.Renderer.SyngeosStations.Options} options
 * @property {{service: Service}} config
 * @property {BP.App.Tasks.handle} onTask
 */

/**
 * Syngeos stations renderer
 * @extends {Component<ComponentProps>}
 */
export default class SyngeosStations extends Component {
  /**
   * @inheritdoc
   * @return {ComponentProps}
   */
  static get defaultProps() {
    return {
      options: {
        items: [],
        title: null,
        messages: [],
      },
      config: { service: undefined },
      onTask: onTaskNoop,
    }
  }

  /**
   * Constructor
   * @param {ComponentProps} props
   */
  constructor(props) {
    super(props)

    this.state = {
      /** @type {Readings[]} */
      readings: [],
      /** @type {string} */
      dateString: null,
    }

    /** @type {Number|undefined} */
    this.refreshIntervalId = undefined

    /** @type {Number|undefined} */
    this.updateDateTimeoutId = undefined

    /** @type {Map<string, AbortController>} */
    this.abortController = new Map()

    /** @type {Intl.DateTimeFormat} - d/m/Y G:i */
    this.intlDateTimeFormatter = new Intl.DateTimeFormat('pl', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
    })

    /** @type {Boolean} - Unmounting flag */
    this.isUnmounting = false
  }

  /**
   * @inheritdoc
   */
  async componentDidMount() {
    await this.updateDataFromCache()

    const updateTaskId = (!this.state.readings.length)
      ? this.props.onTask()
      : undefined

    // Start data updater
    await this.updateData()

    // Start clock updater
    await this.updateClock()

    updateTaskId && this.props.onTask(updateTaskId)
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} prevProps
   */
  async componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.options.items) !== JSON.stringify(this.props.options.items)) {
      await this.updateDataFromCache()
      await this.updateData()
    }
  }

  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    this.refreshIntervalId && window.clearTimeout(this.refreshIntervalId)
    this.updateDateTimeoutId && window.clearTimeout(this.updateDateTimeoutId)
    this.isUnmounting = true

    this.abortController.forEach(SyngeosApi.abort)
    this.abortController.clear()
  }

  /**
   * Update data from cache with fall back to initial state
   * @access protected
   * @return {Promise<void>}
   */
  async updateDataFromCache() {
    const { service } = this.props.config

    /** @type {Object} */
    const newState = {}

    // Get cached readings
    newState.readings = await Promise.all(
      this.props.options.items.map(stationOptions =>
        service.getCachedReadings(stationOptions, true, {})
      )
    )

    // Note: this adds undefined measurements to array by purpose
    await new Promise(resolve => this.setState(newState, () => resolve()))
  }

  /**
   * Request data, update state and start timeout
   * @access protected
   * @return {Promise<void>}
   */
  async updateData() {
    const { service } = this.props.config

    // Stop previous timeout
    this.refreshIntervalId && window.clearTimeout(this.refreshIntervalId)
    this.refreshIntervalId = undefined

    // Abort running requests
    this.abortController.forEach(SyngeosApi.abort)
    this.abortController.clear()

    // Measurements (concurrent promise resolution)
    await Promise.all(this.props.options.items.map(this.updateReadings, this))

    if (this.isUnmounting) {
      return
    }

    // Update readings in interval
    this.refreshIntervalId = window.setTimeout(
      () => this.updateData(),
      service.getReadingsRefreshInterval()
    )
  }

  /**
   * Update reading
   * Note: Doesn't abort previous requests
   * @access protected
   * @param {BP.App.Renderer.SyngeosStation.Options} stationOptions
   * @param {Number} index
   * @return {Promise<void, void>}
   */
  async updateReadings(stationOptions, index) {
    /** @type {Readings} */
    let reading

    const abortController = new AbortController()
    const abortControllerKey = `reading:${stationOptions.id}`

    // Add abort controller
    this.abortController.set(abortControllerKey, abortController)

    try {
      reading = await this.props.config.service.getReadings(stationOptions, abortController.signal)
    } catch (error) {
      // Note: Do not throw on error to not stop Promise.all
      return
    } finally {
      this.abortController.delete(abortControllerKey)
    }

    if (this.isUnmounting) {
      return
    }

    // Update readings in state
    const readings = this.state.readings.map((cmpReadings, cmpIndex) =>
      index === cmpIndex
        ? reading
        : cmpReadings
    )

    return new Promise(resolve => this.setState({ readings }, resolve))
  }

  /**
   * Update clock in 1m interval at full minute
   * @return {Promise<void>}
   */
  async updateClock() {
    this.updateDateTimeoutId && window.clearTimeout(this.updateDateTimeoutId)
    this.updateDateTimeoutId = undefined

    const dateString = SyngeosStations.formatDate(new Date(), this.intlDateTimeFormatter)

    await new Promise(resolve => this.setState({ dateString }, () => resolve()))

    this.updateDateTimeoutId = window.setTimeout(
      () => this.updateClock(),
      MINUTES - Date.now() % MINUTES
    )
  }

  /**
   * @inheritdoc
   */
  render() {
    // Handle data fetch error
    if (!this.state.readings.length) {
      return html`
        <article className="bip-container bip-renderer bip-renderer--bi-station">
          ${'No data'}
        </article>
      `
    }

    // Compute common sensors
    const sensorsMap = new Map()

    for (const stationReadings of this.state.readings) {
      for (const item of stationReadings.items) {
        sensorsMap.set(item.slug, {
          label:   item.label,
          unit:    item.unit,
          hasNorm: item.currentNorm !== null,
        })
      }
    }

    const sensors = Array.from(sensorsMap.values())

    // Compute cols length
    const stationSensorsColsLength = sensors.reduce((acc, stationSensor) =>
      acc + (stationSensor.hasNorm ? 2 : 1),
      0
    )

    return html`
      <article className="bip-container bip-renderer bip-renderer--syngeos-stations">
        <table className="bip-stations-table">
          <thead>
            <!-- Title -->
            ${this.props.options.title && html`
              <tr>
                <td
                  className="bip-stations-caption"
                  colSpan=${stationSensorsColsLength + 2}
                >
                  ${this.props.options.title}
                </td>
              </tr>
            `}
            <!-- Station sensors -->
            <tr className="bip-stations-heading">
              <th rowSpan="2">
                ${'Lokalizacja/ Pomiar'}
              </th>
              ${sensors.map(stationSensor => html`
                <th
                  className="bip-stations-header"
                  colSpan=${stationSensor.hasNorm && 2}
                >
                  ${stationSensor.label}
                </th>
              `)}
              <th rowSpan="2">
                ${'Stan'}
              </th>
            </tr>
            <tr>
              ${sensors.map(stationSensor => html`
                <td className="bip-stations-header bip-stations-header--sensor-unit">
                  ${stationSensor.unit}
                </td>
                ${stationSensor.hasNorm && html`
                  <td className="bip-stations-header bip-stations-header--sensor-unit">
                    %
                  </td>
                `}
              `)}
            </tr>
          </thead>
          <!-- Stations -->
          <tbody>
            ${this.state.readings.map(stationReadings => html`
              <tr>
                <!-- Label -->
                <th className="bip-stations-station-label">
                  ${stationReadings.label || '-'}
                </th>
                <!-- Readings -->
                ${stationReadings.items.length
                  ? stationReadings.items.map(item => html`
                      <td className="bip-stations-station-value">
                        ${item.value.toFixed(0)}
                      </td>
                      ${sensorsMap.get(item.slug).hasNorm && html`
                        <td className="bip-stations-station-value">
                          ${item.thresholdLevel !== null
                            ? item.thresholdLevel.toFixed(0)
                            : '-'
                          }
                        </td>
                      `}
                    `)
                  : html`
                    <td colSpan=${stationSensorsColsLength}>
                      -
                    </td>
                    `
                }
                <!-- Indicator -->
                <td className="bip-stations-indicator">
                  <span
                    className=${`station-indicator station-indicator--small syngeos-current-norm ${getIndicatorClassName(stationReadings.currentNorm)}`}
                  ></span>
                  <span>
                    ${getIndicatorDisplayLabel(stationReadings.currentNorm)}
                  </span>
                </td>
              </tr>
            `)}
          </tbody>
          <tfoot>
            <tr>
              <td className="bip-stations-clock">
                ${this.state.dateString}
              </td>
              <td
                className="bip-stations-animation"
                colSpan=${stationSensorsColsLength + 1}
              >
                <div className="bip-stations-animation__content">
                  ${this.props.options.messages.map(message => html`
                    <span>
                      ${message}
                    </span>
                  `)}
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </article>
    `
  }

  /**
   * Format to date and time
   * @access protected
   * @param {Date} date
   * @param {Intl.DateTimeFormat} dateTimeFormat
   * @return {string}
   */
  static formatDate(date, dateTimeFormat) {
    return dateTimeFormat.format(date)
  }

  /**
   * Populate cache items
   * @access public
   * @param {ComponentProps['config']} config
   * @param {BP.App.Renderer.SyngeosStations.Options} options
   * @return {Promise<void, Error>}
   */
  static populateCache({ service }, options) {
    return service.populateCacheItems(options)
  }

  /**
   * Clean up cache
   * @access public
   * @param {ComponentProps['config']} config
   * @return {Promise<void>}
   */
  static purgeCache({ service }) {
    return service.purgeCache()
  }
}
