import { useEffect, useRef } from 'preact/compat'
import { html } from 'htm/preact'

import { AppError } from '../../Utils/Error.js'
import FatalErrorComponent from '../FatalError.js'

import { noop as onTaskNoop } from '../Progress.js'

import './Website.css'

/**
 * Website renderer
 * note: not possible to check for error because of CORS
 * @param {Object} props
 * @param {BP.App.Renderer.Website.Options} props.options
 * @param {BP.App.Tasks.handle} [props.onTask] - Note: Also triggers when page doesn't load due to error
 */
export default function Website({
  options: {
    url = 'about:blank',
  },
  onTask = onTaskNoop,
}) {
  let urlObject
  let urlValidateError

  /** @type {{current: number|null}} - Task ID reference */
  const taskIdRef = useRef(null)

  // Prepend protocol for protocol-relative URLs
  if (url.search('//') == 0) {
    url = `${window.location.protocol}${url}`
  }

  try {
    /** @throws {TypeError} */
    urlObject = new URL(url)
  } catch (error) {
    urlValidateError = new AppError('Invalid URL')
  }

  // Check for insecure HTTP when on HTTPS to prevent active Mixed content error
  if (
    urlObject &&
    urlObject.protocol === 'http:' &&
    window.location.protocol === 'https:'
  ) {
    urlValidateError = new AppError('Insecure URL')
  }

  // Mount/ Update
  useEffect(() => {
    if (urlValidateError) {
      return
    }

    taskIdRef.current = taskIdRef.current === null
      // Create new
      ? onTask()
      // Replace
      : onTask(taskIdRef.current, true)
  }, [url])

  // Unmout
  useEffect(() => {
    // Remove
    return () =>
      taskIdRef.current && onTask(taskIdRef.current)
  }, [])

  // Cancel task
  // Note: load event triggers also when iframe reloads itself
  const handleOnLoad = () => {
    // Stop
    if (taskIdRef.current) {
      taskIdRef.current = onTask(taskIdRef.current)
    }
  }

  // Show error message
  if (urlValidateError) {
    return html`
      <${FatalErrorComponent} items=${[urlValidateError]} />
    `
  }

  return html`
    <iframe
      className="bip-container bip-renderer bip-renderer--website"
      importance="low"
      loading="lazy"
      referrerpolicy="same-origin"
      sandbox="allow-scripts allow-same-origin"
      src="${urlObject.toString()}"
      onLoad=${handleOnLoad}
    ></iframe>
  `
}
