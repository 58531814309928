/**
 * @typedef { import('./Service.js').SensorDataCurrentNorm } SensorDataCurrentNorm
 */

/**
 * Get current norm indicator class name
 * @param {SensorDataCurrentNorm} currentNorm
 * @return {string}
 */
export function getIndicatorClassName(currentNorm) {
  switch (currentNorm) {
    case 'grade-a': return 'syngeos-current-norm--grade-a'
    case 'grade-b': return 'syngeos-current-norm--grade-b'
    case 'grade-c': return 'syngeos-current-norm--grade-c'
    case 'grade-d': return 'syngeos-current-norm--grade-d'
    case 'grade-e': return 'syngeos-current-norm--grade-e'
    case 'grade-f': return 'syngeos-current-norm--grade-f'
    case undefined:
    default: return 'syngeos-current-norm--x-unknown'
  }
}

/**
 * Get current norm indicator display name
 * @param {SensorDataCurrentNorm} currentNorm
 * @return {string}
 */
export function getIndicatorDisplayLabel(currentNorm) {
  switch (currentNorm) {
    case 'grade-a': return 'B.dobry'
    case 'grade-b': return 'Dobry'
    case 'grade-c': return 'Umiarkowany'
    case 'grade-d': return 'Dostateczny'
    case 'grade-e': return 'Zły'
    case 'grade-f': return 'Bardzo zły'
    case undefined: // No data
    default: return 'Brak danych'
  }
}

/**
 * Convert current norm enum to value
 * @param {SensorDataCurrentNorm} currentNorm
 * @return {number}
 */
export function getCurrentNormValue(currentNorm) {
  switch (currentNorm) {
    case 'grade-a': return 0
    case 'grade-b': return 1
    case 'grade-c': return 2
    case 'grade-d': return 3
    case 'grade-e': return 4
    case 'grade-f': return 5
  }
}

/**
 * Convert current norm value to enum
 * @param {number} currentNormValue
 * @return {SensorDataCurrentNorm}
 */
export function getCurrentNorm(currentNormValue) {
  switch (currentNormValue) {
    case 0: return 'grade-a'
    case 1: return 'grade-b'
    case 2: return 'grade-c'
    case 3: return 'grade-d'
    case 4: return 'grade-e'
    case 5: return 'grade-f'
  }
}
