import { Component } from 'preact'
import { html } from 'htm/preact'

import { noop as onTaskNoop } from '../Progress.js'
import { AirlyApi } from '../../Utils/Api/index.js'

import { getIndicatorClassName } from './AirlyStation/helpers.js'

import './Station/Station.css'
import './Station/indicator.css'
import './AirlyStation/indicator.css'

/**
 * @typedef { import('./AirlyStation/Service.js').default } Service
 * @typedef { import('./AirlyStation/Service.js').Measurement } Measurement
 */

/**
 * @typedef {Object} ComponentProps
 * @property {BP.App.Renderer.AirlyStation.Options} options
 * @property {{service: Service}} config
 * @property {BP.App.Tasks.handle} onTask
 */

/**
 * Airly station renderer
 * @extends {Component<ComponentProps>}
 */
export default class AirlyStation extends Component {
  /**
   * @inheritdoc
   * @return {ComponentProps}
   */
  static get defaultProps() {
    return {
      options: {
        id: undefined,
        label: undefined,
        apiKey: undefined,
      },
      config: { service: undefined },
      onTask: onTaskNoop,
    }
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} props
   */
  constructor(props) {
    super(props)

    this.state = {
      /** @type {Measurement|null} */
      measurement: null,
    }

    /** @type {number|undefined} */
    this.refreshIntervalId = undefined

    /** @type {Map<string, AbortController>} */
    this.abortController = new Map()

    /** @type {boolean} - Unmounting flag */
    this.isUnmounting = false
  }

  /**
   * @inheritdoc
   */
  async componentDidMount() {
    await this.updateDataFromCache()

    const updateTaskId = !this.state.measurement
      ? this.props.onTask()
      : undefined

    await this.updateData()

    updateTaskId && this.props.onTask(updateTaskId)
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} prevProps
   */
  async componentDidUpdate(prevProps) {
    if (
      prevProps.options.id !== this.props.options.id ||
      prevProps.options.label !== this.props.options.label
    ) {
      await this.updateDataFromCache()
      await this.updateData()
    }
  }

  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    this.refreshIntervalId && window.clearTimeout(this.refreshIntervalId)
    this.isUnmounting = true

    this.abortController.forEach(AirlyApi.abort)
    this.abortController.clear()
  }

  /**
   * Update data from cache with fall back to initial state
   * @access protected
   * @return {Promise<void>}
   */
  async updateDataFromCache() {
    const { service } = this.props.config

    const measurement = await service.getCachedMeasurement(this.props.options, true)

    await new Promise(resolve => this.setState({ measurement }, () => resolve()))
  }

  /**
   * Request data, update state and start timeout
   * @access protected
   * @return {Promise<void>}
   */
  async updateData() {
    const { service } = this.props.config

    // Stop previous timeout
    this.refreshIntervalId && window.clearTimeout(this.refreshIntervalId)
    this.refreshIntervalId = undefined

    // Abort running requests
    this.abortController.forEach(AirlyApi.abort)
    this.abortController.clear()

    this.abortController.set('measurement', new AbortController())

    /** @type {Measurement} */
    let measurement

    try {
      measurement = await service.getMeasurement(this.props.options, this.abortController.get('measurement').signal)
    } catch (error) {
      // Noop
    } finally {
      this.abortController.delete('measurement')
    }

    // Do not set state on unmount
    if (this.isUnmounting) {
      return
    }

    if (measurement) {
      await new Promise(resolve => this.setState({ measurement }, () => resolve()))
    }

    // Update measurement in interval
    this.refreshIntervalId = window.setTimeout(
      () => this.updateData(),
      service.getMeasurementsRefreshInterval()
    )
  }

  /**
   * @inheritdoc
   */
  render() {
    // Handle data fetch error
    if (!this.state.measurement) {
      return html`
        <article className="bip-container bip-renderer bip-renderer--airly-station">
          ${'No data'}
        </article>
      `
    }

    return html`
      <article className="bip-container bip-renderer bip-renderer--syngeos-station">
        <table className="bip-station-table">
          <thead>
            <!-- Caption -->
            <tr>
              <th
                className="bip-station-caption"
                colSpan=${this.state.measurement.items.length}
              >
                <!-- Label -->
                <span>
                  ${this.state.measurement.label || '-'}
                </span>
                <!-- Indicator -->
                <span className=${`station-indicator airly-caqi-level ${getIndicatorClassName(this.state.measurement)}`}></span>
              </th>
            </tr>
            <!-- Sensors (labels and units) -->
            <tr>
              ${this.state.measurement.items.map(measurementItem => html`
                <th className="bip-station-header">
                  <div className="bip-station-header__sensor-label">
                    ${measurementItem.label || '-'}
                  </div>
                  <div className="bip-station-header__sensor-unit">
                    ${measurementItem.unit || '-'}
                  </div>
                </th>
              `)}
            </tr>
          </thead>
          <!-- Values -->
          <tbody>
            <tr>
              ${this.state.measurement.items.length
                ?
                  this.state.measurement.items.map(measurementItem => html`
                    <td className="bip-station-value">
                      ${measurementItem.value !== null
                        ? measurementItem.value.toFixed(0)
                        : '-'
                      }
                    </td>
                  `)
                :
                  html`
                    <td className="bip-station-value">
                      ${'Brak danych'}
                    </td>
                  `
              }
            </tr>
          </tbody>
        </table>
      </article>
    `
  }

  /**
   * Populate cache item
   * @access public
   * @param {ComponentProps['config']} config
   * @param {BP.App.Renderer.AirlyStation.Options} options
   * @return {Promise<void>}
   */
  static async populateCache({ service }, options) {
    return service.populateCacheItem(options)
  }

  /**
   * Clean up cache
   * @access public
   * @param {ComponentProps['config']} config
   * @return {Promise<void>}
   */
  static async purgeCache({ service }) {
    return service.purgeCache()
  }
}
