import { openDB, deleteDB } from 'idb'
import * as Sentry from '@sentry/browser'

/**
 * @typedef { import('idb').IDBPDatabase } IDBPDatabase
 */

/**
 * Open default database
 * Note: openDb may throw DOMException:
 *       - `UnknownError: Internal error opening backing store for indexedDB.open.`
 *         When two page instances are opened at the same time on slow machine (RPi)
 *       - `The requested version (1) is less than the existing version (2).`
 *         On downgrade
 * @param {string} name - Database name
 * @param {boolean} debug
 * @param {boolean} [isRetry]
 * @return {Promise<IDBPDatabase>}
 */
export async function openMain(name, debug = false, isRetry = false) {
  try {
    /** @throws {DOMException} */
    return await openDB(name, 2, {
      upgrade(db, oldVersion = 0, newVersion = 2) { // eslint-disable-line no-unused-vars
        // Just create store
        if (oldVersion < 1) {
          db.createObjectStore('state', {})
          return
        }

        // Convert state from collection to keyval schema
        if (oldVersion < 2) {
          // Drop all data without migrating
          db.deleteObjectStore('state')
          db.createObjectStore('state', {})
          return
        }
      }
    })
  } catch (openDbError) {
    // Detect downgrade
    if (
      !isRetry &&
      openDbError instanceof DOMException &&
      openDbError.name === 'VersionError'
    ) {
      if (!debug) {
        Sentry.captureMessage('Deleted main database on downgrade', 'warning')
      } else if (!window.confirm('Main database downgrade detected, delete it?')) {
        throw openDbError
      }

      // Note: This will break features that depend on deleted database data
      //       Such as standalone mode

      // Delete and try again
      await deleteDB(name)

      return openMain(name, debug, true)
    }

    throw openDbError
  }
}
