import AbstractApi from './AbstractApi.js'

/**
 * Biotic Presenter API
 */
export default class Api extends AbstractApi {
  /**
   * Constructor
   * @param {BP.App.Api.Options} options
   * @param {BP.App.PackageMetadata} packageMeta
   */
  constructor(options, packageMeta) {
    super({ baseUrl: options.baseUrl })

    this.options = {
      ...this.options,
      token: null,
      ...options,
    }

    /** @type {BP.App.PackageMetadata} */
    this.packageMeta = {
      name: '@biotic/presenter-client',
      version: '1.0.0',
      semver: '1.0.0',
      ...packageMeta,
    }

    /** @type {Map<string, any>} */
    this.platformDetails = new Map()

    /** @type {string} - Compiled client string */
    this.clientString = this.compileClientString()
  }

  /**
   * Set platform detail
   * @access public
   * @param {string} key
   * @param {any} value
   */
  setPlatformDetail(key, value) {
    this.platformDetails.set(key, value)

    // Compile client string
    this.clientString = this.compileClientString()

    return this
  }

  /**
   * Get
   * @access public
   * @param {string} path
   * @param {AbortSignal} [signal]
   * @return {Promise<any,Error>}
   */
  async get(path, signal = undefined) {
    // Detect absolute path
    const isPathAbsoluteUrl =
      path.startsWith('http:') ||
      path.startsWith('https:') ||
      path.startsWith('//')

    const url = isPathAbsoluteUrl
      ? path
      : `${this.options.baseUrl}/${path}`

    const request = new Request(url, {
      method: 'GET',
      cache: 'no-cache',
      headers: {
        'x-api-token': this.options.token,
        // Note: BI API is configured to allow X-BI-Client header
        // On API Switch extend BiApi.get
        'x-bi-client': this.clientString,
      },
      // Set full referrer in Chrome 85+ https://developers.google.com/web/updates/2020/07/referrer-policy-new-chrome-default
      referrerPolicy: 'no-referrer-when-downgrade',
      signal,
    })

    try {
      // Note: browser may log in console: net::ERR_CONNECTION_TIMED_OUT
      const response = await fetch(request)
      return Api.decodeResponse(response, request)
    } catch (error) {
      Api.handleError(error, request)
    }
  }

  /**
   * Create Client string
   * @access protected
   * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/User-Agent
   * @return {string}
   */
  compileClientString() {
    const product = this.packageMeta.name.replace(/\//g, '-')
    const version = this.packageMeta.semver

    const clientString = `${product}/${version}`

    if (!this.platformDetails.size) {
      return clientString
    }

    /** @type {string[]} */
    const platformDetailsStrings = []

    for (const [key, value] of this.platformDetails) {
      platformDetailsStrings.push(`${key}: ${JSON.stringify(value)}`)
    }

    return `${clientString} (${platformDetailsStrings.join('; ')})`
  }
}
