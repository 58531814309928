import { compile as pathCompile } from 'path-to-regexp'

import { openDB } from 'idb'

import { HOURS, MINUTES } from '../../../Constants/DateTime.js'

/**
 * @typedef { import('idb').IDBPDatabase } IDBPDatabase
 * @typedef { import('path-to-regexp').PathFunction } PathFunction
 * @typedef { import('../../../Utils/Api').BiApi } BiApi
 */

/**
 * @typedef {Object} DataWrapper - Cache data wrapper object
 * @property {string} id
 * @property {number} receivedAt
 * @property {any} data
 */

/**
 * @typedef {Object} BiStationSensor - API response station sensor
 * @property {string} code
 * @property {string} unitSymbol
 * @property {string} label
 * @property {string} htmlLabel
 * @property {boolean} hasNorm
 */

/**
 * @typedef {Object} BiMeasurement - API response measurement
 * @property {number|null} aqiPl
 * @property {string} [street]
 * @property {string} [description]
 * @property {string} deviceId
 * @property {string[]} sensors
 * @property {BiValueObject[]|undefined} values
 */

/**
 * @typedef {Object} BiValueObject - API response value object
 * @property {string} code
 * @property {number|null} [value]
 * @property {number|null} [normPercent]
 */

/**
 * @typedef {Object} Measurement - Decorated measurement
 * @property {string} label
 * @property {number|null|undefined} aqiPl
 * @property {Map<string, BiValueObject>} valuesMap
 */

/**
 * @typedef {Object} Config
 * @property {string} cacheDbName - Cache database name
 * @property {string[]} showSensorValues - Whitelisted sensor values, as defined in API
 * @property {Object<string, string>} customSensorLabels - Custom sensor labels
 * @property {EndpointConfig} stationSensors - Station sensors config
 * @property {EndpointConfig} measurement - Measurement config
 */

/**
 * @typedef {Object} EndpointConfig
 * @property {string} endpoint
 * @property {number} interval
 */

/**
 * BI station API Service
 */
export default class Service {
  /**
   * Constructor
   * @param {BiApi} biApi
   * @param {Partial<Config>} config
   */
  constructor(biApi, config) {
    /**
     * Beskid instruments API
     * @access protected
     * @type {BiApi}
     */
    this.biApi = biApi

    /**
     * Cache database
     * @access protected
     * @type {IDBPDatabase|undefined}
     */
    this.cacheDb = undefined

    /**
     * Config
     * @access protected
     * @type {Config}
     */
    this.config = {
      cacheDbName: '@biotic/presenter-client/renderer/bi-station',
      showSensorValues: [
        'pm25',
        'pm10',
        'humidity',
        'airPressure',
        'temperature',
      ],
      customSensorLabels: {
        'pm25': 'PM2,5',
        'pm10': 'PM10',
        'humidity': 'Wilg.',
        'airPressure': 'Ciśn',
        'temperature': 'Temp.',
      },
      stationSensors: {
        endpoint: '//air.beskidinstruments.com/api/v1/stations/sensors/',
        interval: 24 * HOURS,
      },
      measurement: {
        endpoint: '//air.beskidinstruments.com/api/rest/:id/',
        interval: 10 * MINUTES,
      },
      ...config,
    }

    /**
     * Compiled API endpoints
     * @access protected
     * @type {Object<string, PathFunction>}
     */
    this.endpointPathFn = {
      stationSensors: pathCompile(this.config.stationSensors.endpoint),
      measurement: pathCompile(this.config.measurement.endpoint),
    }
  }

  /**
   * Initialize async stuff
   * Note: This may throw DOMException: UnknownError: Internal error opening backing store for indexedDB.open.
   * @access public
   * @return {Promise<this>}
   */
  async initialize() {
    this.cacheDb = await openDB(this.config.cacheDbName, 1, {
      upgrade(db) {
        db.createObjectStore('keyval', { keyPath: 'id' })
          .createIndex('receivedAt', 'receivedAt')

        db.createObjectStore('measurement', { keyPath: 'id' })
          .createIndex('receivedAt', 'receivedAt')
      }
    })

    return this
  }

  /**
   * Get measurements refresh interval
   * @access public
   * @return {Number}
   */
  getMeasurementsRefreshInterval() {
    return this.config.measurement.interval
  }

  /**
   * Get station sensors from cache if not stale or fetch
   * @access public
   * @param {AbortSignal} [abortSignal]
   * @return {Promise<BiStationSensor[],Error>}
   */
  async getStationSensors(abortSignal = undefined) {
    const data = await this.getCachedStationSensors()

    return data || this.fetchStationSensors(abortSignal)
  }

  /**
   * Get cached station sensors
   * @access public
   * @param {Boolean} [skipStaleCheck]
   * @param {Object} [data] - Fallback
   * @return {Promise<BiStationSensor[]|undefined>}
   */
  async getCachedStationSensors(skipStaleCheck = false, data = undefined) {
    // Load from cache
    /** @type {DataWrapper|undefined} */
    const dataWrapper = await this.cacheDb.get('keyval', 'sensors')

    if (
      dataWrapper &&
      (skipStaleCheck || !Service.isStale(dataWrapper.receivedAt, this.config.stationSensors.interval))
    ) {
      data = dataWrapper.data
    }

    return data
      ? this.decorateStationSensors(data)
      : data
  }

  /**
   * Get station sensors saving result in cache
   * @access protected
   * @param {AbortSignal} [abortSignal]
   * @return {Promise<BiStationSensor[], Error>}
   * @throws {Error}
   */
  async fetchStationSensors(abortSignal = undefined) {
    /** @type {BiStationSensor[]} */
    const data = await this.biApi.get(
      this.endpointPathFn.stationSensors(),
      abortSignal
    )

    // Put in cache
    await this.cacheDb.put('keyval', {
      id: 'sensors',
      data,
      receivedAt: Date.now(),
    })

    return this.decorateStationSensors(data)
  }

  /**
   * Get measurement from cache if not stale or fetch
   * @access public
   * @param {BP.App.Renderer.BiStation.Options} stationOptions
   * @param {AbortSignal} [abortSignal]
   * @return {Promise<Measurement,Error>}
   */
  async getMeasurement(stationOptions, abortSignal = undefined) {
    const data = await this.getCachedMeasurement(stationOptions)

    return data || this.fetchMeasurement(stationOptions, abortSignal)
  }

  /**
   * Get measurement saving result in cache
   * @access protected
   * @param {BP.App.Renderer.BiStation.Options} stationOptions
   * @param {AbortSignal} [abortSignal]
   * @return {Promise<Measurement, Error>}
   * @throws {Error}
   */
  async fetchMeasurement(stationOptions, abortSignal = undefined) {
    /** @type {BiMeasurement} */
    const data = await this.biApi.get(
      this.endpointPathFn.measurement({ id: stationOptions.id }),
      abortSignal
    )

    // Put in cache
    await this.cacheDb.put('measurement', {
      id: stationOptions.id,
      data,
      receivedAt: Date.now(),
    })

    return this.decorateStationMeasurement(data, stationOptions)
  }

  /**
   * Get cached measurement
   * @access public
   * @param {BP.App.Renderer.BiStation.Options} stationOptions
   * @param {Boolean} [skipStaleCheck]
   * @param {Object} [data] - Fallback
   * @return {Promise<Measurement|undefined>}
   */
  async getCachedMeasurement(stationOptions, skipStaleCheck = false, data = undefined) {
    // Load from cache
    /** @type {DataWrapper|undefined} */
    const dataWrapper = await this.cacheDb.get('measurement', stationOptions.id)

    if (
      dataWrapper &&
      (skipStaleCheck || !Service.isStale(dataWrapper.receivedAt, this.config.measurement.interval))
    ) {
      data = dataWrapper.data
    }

    // Decorate object
    return data
      ? this.decorateStationMeasurement(data, stationOptions)
      : data
  }

  /**
   * Populate cache item
   * @access public
   * @param {{items: BP.App.Renderer.BiStation.Options[]}} stationsOptions
   * @return {Promise<void, Error>}
   */
  async populateCacheItems(stationsOptions) {
    // Get station sensors once
    const stationSensors = this.getCachedStationSensors(true)

    if (!stationSensors) {
      await this.fetchStationSensors()
    }

    // Note: this checks if cached data are still valid to prevent race condition
    await Promise.all(stationsOptions.items.map(stationOptions =>
      this.getMeasurement(stationOptions)
    ))
  }

  /**
   * Populate cache items
   * @access public
   * @param {BP.App.Renderer.BiStation.Options} stationOptions
   * @return {Promise<void, Error>}
   */
  async populateCacheItem(stationOptions) {
    await this.populateCacheItems({
      items: [stationOptions]
    })
  }

  /**
   * Clean up cache
   * @access public
   * @return {Promise<void>}
   */
  async purgeCache() {
    const { interval } = this.config.measurement

    /** @type {DataWrapper[]} */
    const measurements = await this.cacheDb.getAll('measurement')

    const purgePromises = measurements
      // Check if stale
      .filter(dataWrapper => Service.isStale(dataWrapper.receivedAt, interval))
      // Delete
      .map(dataWrapper => this.cacheDb.delete('measurement', dataWrapper.id))

    await Promise.all(purgePromises)
  }

  /**
   * Check if data is stale
   * @access protected
   * @param {number} receivedAt
   * @param {number} maxAge
   * @return {boolean}
   */
  static isStale(receivedAt, maxAge) {
    return (
      maxAge !== Infinity &&
      Date.now() > receivedAt + maxAge
    )
  }

  /**
   * Decorate station sensors
   * @access protected
   * @param {BiStationSensor[]} stationSensors
   * @return {BiStationSensor[]}
   */
  decorateStationSensors(stationSensors) {
    return this.config.showSensorValues
      // Filter station sensors and use order
      .map((/** @type {string} */code) =>
        stationSensors.find(stationSensor => stationSensor.code === code)
      )
      // Remove missing ones
      .filter((/** @type {BiStationSensor|undefined} */stationSensor) =>
        stationSensor !== undefined
      )
      // Add label
      .map((/** @type {BiStationSensor} */stationSensor) => ({
        ...stationSensor,
        label: this.config.customSensorLabels[stationSensor.code],
      }))
  }

  /**
   * Decorate station measurement
   * @access protected
   * @param {BiMeasurement|Object} measurement
   * @param {BP.App.Renderer.BiStation.Options} stationOptions
   * @return {Measurement}
   */
  decorateStationMeasurement(measurement, stationOptions) {
    // Resolve label
    const label =
      stationOptions.label ||
      measurement.street ||
      measurement.description ||
      measurement.deviceId

    // Construct object values map
    /** @type {Measurement['valuesMap']} */
    const valuesMap = new Map(measurement.values
      ? measurement.values
        // Keep only whitelisted sensors
        .filter((/** @type {BiValueObject} */valueObject) =>
          this.config.showSensorValues.includes(valueObject.code)
        )
        // Assign valueObject to code
        .map((/** @type {BiValueObject} */valueObject) => [
          valueObject.code,
          valueObject,
        ])
      : []
    )

    return {
      label,
      aqiPl: measurement.aqiPl,
      valuesMap,
    }
  }
}
