import { Component, createRef } from 'preact'
import { html } from 'htm/preact'

import { AppError } from '../../Utils/Error.js'
import FatalErrorComponent from '../FatalError.js'

import './YoutubePlaylist.css'

/* global YT */

/**
 * @typedef { import('preact').RefObject<HTMLElement> } HTMLElementRef
 */

/**
 * @typedef {Object} ComponentProps
 * @property {BP.App.Renderer.YoutubePlaylist.Options} options
 * @property {BP.App.Tasks.handle} onTask
 */

/**
 * Youtube renderer
 * @see https://developers.google.com/youtube/iframe_api_reference
 * @extends {Component<ComponentProps>}
 */
class YoutubePlaylist extends Component {
  /**
   * @inheritdoc
   * @param {ComponentProps} props
   */
  constructor(props) {
    super(props)

    this.state = {
      errors: []
    }

    /** @type {YT.Player} */
    this.youtubePlayer = null

    /** @type {Object} */
    this.youtubeOptions = {
      // width: '426',
      // height: '240',
      // videoId: undefined,
      host: `${window.location.protocol === 'https:' ? 'https:' : 'http:'}//www.youtube-nocookie.com`,
      playerVars: {
        autoplay: 0,
        color: 'white',
        controls: 0, // Play/ Pause still works
        disablekb: 1,
        fs: 0,
        hl: 'pl',
        loop: 1,
        modestbranding: 1,
        origin: window.location.origin,
      },
    }

    /** @type {HTMLElementRef} */
    this.ref = createRef()

    /** @var {number} - Task ID */
    this.initTaskId = null

    // Bind events
    this.handlePlayerReady = this.handlePlayerReady.bind(this)
    this.handlePlayerStateChange = this.handlePlayerStateChange.bind(this)
    this.handlePlayerError = this.handlePlayerError.bind(this)
  }

  /**
   * @inheritdoc
   */
  async componentDidMount() {
    this.initTaskId = this.props.onTask()

    try {
      this.youtubePlayer = await YoutubePlaylist.getYoutubePlayer(this.ref.current, {
        ...this.youtubeOptions,
        events: {
          onReady: this.handlePlayerReady,
          onStateChange: this.handlePlayerStateChange,
          onError: this.handlePlayerError,
        },
      })
    } catch (error) {
      // TODO: Retry
      // TODO: Add playback error handler
      this.initTaskId = this.props.onTask(this.initTaskId)

      this.setState({
        errors: [...this.state.errors, error]
      })

      return
    }

    // Initial state is CUED
    if (this.youtubePlayer.getPlayerState() === YT.PlayerState.PLAYING) {
      this.youtubePlayer.pauseVideo()
    }

    const playlist = YoutubePlaylist.parseOptions(this.props.options.items)

    // Note: when using cuePlaylist, must wait some time with playVideo afterwards
    this.youtubePlayer.loadPlaylist(playlist)

    // Note: need to reenable loop for after loadPlaylist
    this.youtubePlayer.setLoop(true)
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} prevProps
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.options.items !== this.props.options.items &&
      this.initTaskId
    ) {
      this.initTaskId = this.props.onTask(this.initTaskId, true)
    }
  }

  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    if (this.youtubePlayer) {
      this.youtubePlayer.destroy()
    }

    if (this.initTaskId) {
      this.props.onTask(this.initTaskId)
    }
  }

  /**
   * Handle player ready event
   * @access protected
   * @param {YT.Event} event
   */
  handlePlayerReady({ target: youtubePlayer }) {
    // Note: setLoop doesn't work before setting playlist
    youtubePlayer.setLoop(true)
    youtubePlayer.mute()
  }

  /**
   * Handle player state change event
   * @access protected
   * @param {YT.Event} event - For data see YT.PlayerState
   */
  handlePlayerStateChange(event) {
    if (
      event.data === YT.PlayerState.PLAYING &&
      this.initTaskId
    ) {
      this.initTaskId = this.props.onTask(this.initTaskId)
    }
  }

  /**
   * Handle player error
   * Note: Doesn't trigger when connection breaks, just switches to BUFFERING state
   * @access protected
   * @param {YT.Event} event
   */
  handlePlayerError(event) { // eslint-disable-line no-unused-vars
  }

  /**
   * @inheritdoc
   */
  render() {
    // Error
    if (this.state.errors.length) {
      return html`
        <${FatalErrorComponent} items=${this.state.errors} />
      `
    }

    // Note: When using ref, YouTube IFrame Player API appends iframe element instead of replacing element one
    return html`
      <div className="bip-container bip-renderer bip-renderer--youtube-playlist">
        <div
          className="bip-renderer--youtube-playlist__element"
          ref=${this.ref}
        ></div>
      </div>
    `
  }

  /**
   * Get Youtube player
   * @access protected
   * @param {HTMLElement} $player
   * @param {Object} options
   * @return {Promise<YT.Player>}
   */
  static async getYoutubePlayer($player, options) {
    // YT API ready
    if (window.YT) {
      return new Promise((resolve) =>
        YoutubePlaylist.createYoutubePlayer(window.YT, $player, options, resolve)
      )
    }

    const onYouTubeIframeAPIReadyEventName = 'onYouTubeIframeAPIReady'

    return new Promise((resolve, reject) => {
      window[onYouTubeIframeAPIReadyEventName] = () => {
        YoutubePlaylist.createYoutubePlayer(window.YT, $player, options, resolve)

        // Cleanup
        delete window[onYouTubeIframeAPIReadyEventName]
      }

      /** @throws {Error} */
      YoutubePlaylist.initializeYoutubeIframeApi()
        .catch(reject)
    })
  }

  /**
   * Load YouTube IFrame Player API
   * like [ga](https://developers.google.com/analytics/devguides/collection/analyticsjs)
   * @access protected
   * @return {Promise}
   * @throws {Error}
   */
  static async initializeYoutubeIframeApi() {
    const $firstScriptTag = document.querySelector('script')
    const $script = document.createElement('script')
    const eventOptions = { once: true }

    await new Promise((resolve, reject) => {
      $script.src = '//www.youtube.com/iframe_api'

      $script.addEventListener('load', () => resolve(), eventOptions)
      $script.addEventListener('error', () => reject(new AppError('Cannot load YouTube IFrame Player API')), eventOptions)

      $firstScriptTag.parentNode.insertBefore($script, $firstScriptTag)
    })

    // Cleanup
    $script.remove()
  }

  /**
   * Get Youtube player (IFrame API must be available)
   * @access protected
   * @param {Object} YT
   * @param {HTMLElement} $player
   * @param {Object} options
   * @param {Function} resolve
   * @return {YT.Player}
   */
  static createYoutubePlayer(YT, $player, options, resolve) {
    // Warning: player is not ready to operate yet, must wait for onReady event
    return new YT.Player($player, {
      ...options,
      events: {
      ...options.events,
        onReady: (/** @type {YT.Event} */ event) => {
          // Resolve
          resolve(event.target)
          // Manually fire event
          options.events.onReady(event)
        },
      }
    })
  }

  /**
   * Parse content into playlist
   * @access protected
   * @param {string|string[]} items
   * @return {string[]}
   * @throws {Error}
   */
  static parseOptions(items) {
    // Handle legacy version
    if (typeof items === 'string') {
      items = items.split('\r\n')
    }

    return items.map((videoUrl) => {
      /** @type {string[]|null} */
      const match = (
        videoUrl.match(YoutubePlaylist.videoUrlRegexp) ||
        videoUrl.match(YoutubePlaylist.videoUrlShortRegexp)
      )

      if (!match) {
        throw new AppError('Invalid YouTube Video ID')
      }

      return match[1]
    })
  }
}

// Browser URL
YoutubePlaylist.videoUrlRegexp = /^https:\/\/www\.youtube\.com\/watch\?v=([^&]+)/
// YT Share dialog
YoutubePlaylist.videoUrlShortRegexp = /^https:\/\/youtu\.be\/([^?]+)/

export default YoutubePlaylist
