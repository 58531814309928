/// <reference types="network-information-types" />

import { useEffect, useState } from 'preact/compat'
import { html } from 'htm/preact'

import './Dashboard.css'

/**
 * Boot dashboard
 * @param {Object} props
 * @param {BP.App.PackageMetadata} props.packageMetadata
 * @param {string} props.screenId
 * @param {BP.App.Api.Response.ScreenConfig} props.screenConfig
 */
export default function Dashboard({
  packageMetadata,
  screenId,
  screenConfig,
}) {
  const [serviceWorker, setServiceWorker] = useState(null)

  useEffect(() => {
    // Note: Not 100% reliable: doesn't show in inital registration on ctrl+F5
    setServiceWorker(navigator.serviceWorker && navigator.serviceWorker.controller)
  }, [navigator.serviceWorker && navigator.serviceWorker.controller])

  // @ts-ignore
  const networkInformation = navigator.connection

  return html`
    <article className="bip-container bip-dashboard">
      <h1 className="bip-dashboard__heading">
        Biotic presenter<br />
        v${packageMetadata.semver}
      </h1>
      <dl className="bip-description-list">
        <!-- Screen ID -->
        <dt>
          ${'Screen ID'}
        </dt>
        <dd>
          ${screenId}
        </dd>

        <!-- Resolution -->
        <dt>
          ${'Resolution'}
        </dt>
        <dd>
          ${screenConfig.resolution.width && screenConfig.resolution.height
            ? `${screenConfig.resolution.width} × ${screenConfig.resolution.height}`
            : 'auto'
          }
        </dd>

        <!-- Offline support -->
        <dt>
          ${'Offline support'}
        </dt>
        <dd>
          ${serviceWorker ? 'yes' : 'no'}
        </dd>

        <!-- Network -->
        <dt>
          ${'Network'}
        </dt>
        <dd>
          ${networkInformation
            ? `${networkInformation.effectiveType} (${networkInformation.downlink} Mbps)`
            : 'N/A'
          }
        </dd>
      </dl>
    </article>
  `
}
