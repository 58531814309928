/**
 * @typedef {(item: BP.App.Content.PlaylistItem[]) => BP.App.Content.PlaylistItem[]} compilerInterface
 */

/**
 * Compile
 * @param {BP.App.Content.PlaylistItem[]} items
 * @return {BP.App.Content.PlaylistItem[]}
 */
export default items => {
  /** @type {compilerInterface[]} */
  const compilers = [
    removeDisabled,
    replaceReferences,
    // removeDisabledSimple,
  ]

  return compilers.reduce(
    (items, compiler) => compiler(items),
    items
  )
}

/**
 * Replace reference items by it's targets
 * @type {compilerInterface}
 */
function replaceReferences(items) {
  return items
    .map(item => {
      // TypeScript Type guard
      if (item.type === 'reference') {
        const target = items[item.options.index]

        // Note: With other way round (destructuring item) problem with TypeScript
        return {
          ...target,
          duration: item.duration,
          disabled: item.disabled,
        }
      }

      return item
    })
}

/**
 * Easy implementation after references have been compiled
 * @type {compilerInterface}
 */
function removeDisabledSimple(items) { // eslint-disable-line no-unused-vars
  return items
    .filter(item => !item.disabled)
}

/**
 * Remove disabled items and property, reassigning reference indexes
 * @type {compilerInterface}
 */
function removeDisabled(items) {
  // Filter out disabled of references to disabled
  const filteredItems = items
    .filter(item =>
      item.type === 'reference' && items[item.options.index].disabled
        ? false
        : !item.disabled
    )

  // Reindex reference indexes
  return filteredItems
    .map(item => {
      // Copy while removing disabled prop
      const { disabled, ...outputItem } = item // eslint-disable-line no-unused-vars

      // Update reference index
      if (item.type === 'reference') {
        // Get new index
        const newRefIndex = filteredItems.indexOf(items[item.options.index])

        if (newRefIndex === -1) {
          throw new ReferenceError(`Reference: Invalid index ${item.options.index}`)
        }

        outputItem.options = { ...item.options, index: newRefIndex }
      }

      return outputItem
    })
}
