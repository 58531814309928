import { html } from 'htm/preact'

import './Progress.css'

/**
 * Noop
 */
export function noop() { return null }

/**
 * Progress indicator
 * @param {Object} props
 * @param {BP.App.Tasks.Id[]} [props.tasks]
 */
export default function Progress({
  tasks = []
}) {
  return html`
    <div
      className="bip-container bip-progress-indicator"
      hidden=${!tasks.length}
    >
      <div className="bip-progress-indicator__spinner uk-icon uk-spinner">
        <svg
          width="135"
          height="135"
          viewBox="0 0 30 30"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            fill="none"
            stroke="#000"
            stroke-width=".2"
            cx="15"
            cy="15"
            r="14"
          />
        </svg>
      </div>
      <div className="bip-progress-indicator__text">
        Working…
      </div>
    </div>
  `
}
