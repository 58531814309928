/**
 * @typedef { import('../db.js').IDBPDatabase } IDBPDatabase
 */

/** @var {Object} - Initial screen config */
const initial = {
  resolution: { width: null, height: null },
  isLed: null,
}

/**
 * Get screen config from location with DB fallback + update
 * @param {Location|URL} location
 * @param {IDBPDatabase} db
 * @return {Promise<Object|null>}
 */
export async function update(location, db) {

  // Read from database
  const fromDb = await getFromDb(db)

  // Read from URL Search params
  const fromUrlSearchParams = getFromUrlSearchParams(new URLSearchParams(location.search))

  const config = {
    ...initial,
    ...fromDb,
    ...fromUrlSearchParams
  }

  // Update DB only when params are defined. Undefined means keep as is
  if (
    fromUrlSearchParams.resolution !== undefined ||
    fromUrlSearchParams.isLed !== undefined
  ) {
    // TODO: Move to App
    await setInDb(config, db)
  }

  return config
}

/**
 * Get from URL search params
 * @param {URLSearchParams} urlSearchParams
 * @return {Object}
 */
export function getFromUrlSearchParams(urlSearchParams) {
  /** @type {{ resolution?: { width: number|null, height: number|null }, isLed?: boolean|null }} */
  const config = {}

  // Resolution
  if (urlSearchParams.has('resolution')) {
    const resolution = { width: null, height: null }
    const resolutionString = urlSearchParams.get('resolution')

    if (resolutionString) {
      [resolution.width, resolution.height] = resolutionString
        .split('x')
        .map(Number.parseFloat)
    }

    config.resolution = resolution
  }

  // LED switch
  if (urlSearchParams.has('is-led')) {
    const isLedString = urlSearchParams.get('is-led')

    config.isLed = isLedString
      ? JSON.parse(isLedString)
      : null
  }

  return config
}

/**
 * Get from DB
 * @param {IDBPDatabase} db
 * @return {Promise<Object|null,Error>}
 */
async function getFromDb(db) {
  const config = await db.get('state', 'screenConfig')

  return config || null
}

/**
 * Set in DB
 * @param {Object} screenConfig
 * @param {IDBPDatabase} db
 * @return {Promise<void, Error>}
 */
export function setInDb(screenConfig, db) {
  return db.put('state', screenConfig, 'screenConfig')
}
