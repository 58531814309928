import { Component, createRef } from 'preact'
import { html } from 'htm/preact'

import FatalErrorComponent from '../FatalError.js'

import './VideoPlaylist.css'

/**
 * @typedef { import('preact').RefObject<HTMLVideoElement> } HTMLVideoElementRef
 */

/**
 * @typedef {Object} ComponentConfig
 * @property {boolean} manualCacheRead - Use cache as video src
 * @property {Cache} cache - Response cache
 */

/**
 * @typedef {Object} ComponentProps
 * @property {BP.App.Renderer.VideoPlaylist.Options} options
 * @property {ComponentConfig} config
 */

/**
 * @typedef {Object} ComponentState
 * @property {string|null} src - Video URL
 * @property {Error|null} playError - Rejected video.play() cause
 */

/**
 * Video playlist renderer
 * @extends {Component<ComponentProps, ComponentState>}
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLVideoElement
 * @todo handle `AbortError: The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22`
 * @deprecated - Use Video renderer at this one has problems on slow CPUs
 */
export default class VideoPlaylist extends Component {
  /**
   * @inheritdoc
   * @return {ComponentProps}
   */
  static get defaultProps() {
    return {
      options: {
        items: [],
      },
      config: {
        manualCacheRead: false,
        cache: null,
      },
    }
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} props
   */
  constructor(props) {
    super(props)

    /** @type {ComponentState} */
    this.state = {
      src: null,
      playError: null,
    }

    /** @type {string} - Video Blob URL */
    this.blobSrc = null

    /** @type {Number} - Current video index */
    this.currentVideoIndex = null

    /** @type {boolean} - Video is ready to be paused */
    this.isPauseReady = false

    /** @type {HTMLVideoElementRef} */
    this.ref = createRef()

    // Bind events
    this.handleVideoEnded = this.handleVideoEnded.bind(this)
    this.handleDocumentVisibilityChange = this.handleDocumentVisibilityChange.bind(this)
  }

  /**
   * @inheritdoc
   */
  async componentDidMount() {
    // Note: On play errors, ref is empty
    this.ref.current.addEventListener('ended', this.handleVideoEnded, true)

    document.addEventListener('visibilitychange', this.handleDocumentVisibilityChange, true)

    if (this.props.options.items.length) {
      this.playSingleVideo(0)
    }
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} prevProps
   */
  componentDidUpdate(prevProps) {
    // Start new playlist
    // Note: alternatively may compare each item using Array#every
    if (JSON.stringify(prevProps.options.items) !== JSON.stringify(this.props.options.items)) {
      if (this.props.options.items.length) {
        this.playSingleVideo(0)
      } else {
        // Clean up previous video
        this.stopVideo()
        this.setState({ src: null })
      }
    }
  }

  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    this.stopVideo()

    if (this.ref.current) {
      this.ref.current.removeEventListener('ended', this.handleVideoEnded, true)
    }

    window.document.removeEventListener('visibilitychange', this.handleDocumentVisibilityChange, true)
  }

  /**
   * Handle document visibility change
   * @access protected
   */
  handleDocumentVisibilityChange() {
    if (
      this.currentVideoIndex === null ||
      this.ref.current === null ||
      // Check if video is ready to be paused
      // As fast visibility switching may result in `DOMException: The play() request was interrupted`
      // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
      this.isPauseReady === false
    ) {
      return
    }

    if (window.document.hidden) {
      this.ref.current.pause()
    } else {
      this.ref.current.play()
    }
  }

  /**
   * Handle video ended
   * Note: doesn't fire in loop mode
   * @access protected
   */
  handleVideoEnded() {
    const nextVideoIndex = this.currentVideoIndex === null
      ? 0
      : (this.currentVideoIndex + 1) % this.props.options.items.length

    this.playSingleVideo(nextVideoIndex)
  }

  /**
   * Play single video
   * @access protected
   * @param {Number} index
   * @return {Promise<void, Error>}
   */
  async playSingleVideo(index = 0) {
    const item = {
      url: null,
      src: null,
      ...this.props.options.items[index],
    }

    const src = item.url || item.src

    // Ignore on loops
    if (src !== this.state.src) {
      // Detect type playback capability (may use [Media Capabilities API](https://developer.mozilla.org/en-US/docs/Web/API/Media_Capabilities_API))
      // or MediaSource.isTypeSupported
      // Note: This won't work if component is in error state as ref is not rendered
      if (item.type && !this.ref.current.canPlayType(item.type)) {
        throw new Error(`Cannot play video type ${item.type}`)
      }

      let blobSrc = null

      // Lookup in cache
      // @deprecated used only when service worker is disabled
      if (this.props.config.manualCacheRead) {
        /** @type {Response|undefined} */
        const cachedResponse = await this.props.config.cache.match(src)

        // Cache first
        if (cachedResponse) {
          blobSrc = URL.createObjectURL(await cachedResponse.blob())
        }
      }

      this.stopVideo()

      this.blobSrc = blobSrc

      await new Promise(resolve => this.setState({ src }, () => resolve()))
    // When component is in error state, don't attempt to play again
    } else if (this.state.playError) {
      return
    }

    this.isPauseReady = false
    this.currentVideoIndex = index

    // Skip when video element is not shown
    if (!this.ref.current) {
      return
    }

    /** @type {Error} */
    let playError = null

    // Play when autoplay: false
    // https://developers.google.com/web/updates/2016/03/play-returns-promise
    try {
      /**
       * @throws {DOMException}
       * - video isn't muted and user didn't interacted; to skip use `--no-user-gesture-required` chromium flag
       * - blocked by CORS policy - missing access control headers in response
       */
      await this.ref.current.play()
    } catch (error) {
      playError = error
    } finally {
      this.isPauseReady = !playError
    }

    if (this.state.playError !== playError) {
      await new Promise(resolve => this.setState({ playError }, () => resolve()))
    }
  }

  /**
   * Stop video
   */
  stopVideo() {
    if (
      this.currentVideoIndex !== null &&
      this.ref.current
    ) {
      this.isPauseReady && this.ref.current.pause()
      this.cleanupAfterVideoEnded()
    }

    this.currentVideoIndex = null
    this.blobSrc = null
  }

  /**
   * Clean up memory after video has finished
   * https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications#Example_Using_object_URLs_with_other_file_types
   * In Chrome cannot rely on
   * - canplaythrough as it fires before video is fully buffered https://bugs.chromium.org/p/chromium/issues/detail?id=73609, https://support.google.com/chrome/thread/25510119?hl=en
   * - buffered.end(0) === duration when video is cached
   * In effect Chrome fires range requests to revoked blob
   */
  cleanupAfterVideoEnded() {
    if (this.blobSrc && /^blob:.*$/.test(this.blobSrc)) {
      URL.revokeObjectURL(this.blobSrc)
    }
  }

  /**
   * @inheritdoc
   */
  render() {
    if (this.state.playError) {
      return FatalErrorComponent({ items: [this.state.playError] })
    }

    return html`
      <div className="bip-container bip-renderer bip-renderer--video-playlist">
        <video
          className="bip-renderer--video-playlist__element"
          ref=${this.ref}
          muted
          playsinline
          preload="auto"
          controlslist="nodownload nofullscreen noremoteplayback"
          disablePictureInPicture
          disableRemotePlayback
          crossorigin="anonymous"
          src=${this.blobSrc || this.state.src}
        ></video>
      </div>
    `
  }

  //// Helpers

  /**
   * Create video request info
   * @access protected
   * @param {string} url
   * @param {Headers} [headers]
   * @param {AbortSignal} signal
   * @return {Request}
   */
  static createRequest(url, headers = undefined, signal = undefined) {
    return new Request(url, {
      method: 'GET',
      headers,
      signal,
    })
  }

  /**
   * Populate cache
   * and indirectly populate metadata database in workbox-expiration plugin
   * @access public
   * @param {ComponentConfig} config
   * @param {BP.App.Renderer.VideoPlaylist.Options} options
   * @param {AbortSignal} [abortSignal]
   * @return {Promise<void>}
   */
  static async populateCache({ cache }, options, abortSignal = undefined) {
    // Generate abort controllers for each item
    /** @type {{item: BP.App.Renderer.VideoPlaylist.Item, abortController: AbortController}[]} */
    const tasks = options.items
      .map(item => ({ item, abortController: new AbortController() }))

    // Add event to abort all signals
    if (abortSignal) {
      abortSignal.addEventListener('abort', () =>
        tasks.map(({ abortController }) => abortController.abort())
      )
    }

    await Promise.all(
      tasks.map(async ({ item, abortController }) => {
        const request = VideoPlaylist.createRequest(item.url || item.src, undefined, abortController.signal)

        // Check in cache (Checking in DB is faster, however not part of workbox-expiration public API)
        if (await cache.match(request)) {
          return
        }

        // Add to cache
        // Note: Responses with redirect are followed and stored as result response
        return cache.add(request)
      })
    )
  }

  /**
   * Populate singe cache item, with optional progress callback
   * Note: Responses with redirect are store redirect result response
   *       Description: https://stackoverflow.com/questions/45434470/only-in-chrome-service-worker-a-redirected-response-was-used-for-a-reque
   *       Possile solution: https://github.com/GoogleChrome/workbox/blob/v5.1.3/packages/workbox-precaching/src/PrecacheController.ts#L283-L285
   * [NOT USED]
   * @access protected
   * @param {ComponentConfig} config
   * @param {BP.App.Renderer.VideoPlaylist.Item} item
   * @param {(bytes: number, percentage?: number) => void} [progressCallback]
   * @param {AbortController} [abortController]
   * @return {Promise<void>}
   * @see https://javascript.info/fetch-progress
   * @see https://gist.github.com/damieng/157c0a0f9285d2fc78014a75cfc8f3e4
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Body/body
   */
  static async populateCacheItem({ cache }, item, progressCallback = undefined, abortController = undefined) {
    // Note: May check if response is redirected and cache result
    const request = new Request(item.url || item.src, {
      method: 'GET',
      signal: abortController && abortController.signal,
    })

    const response = await window.fetch(request)

    if (!response.ok) {
      throw Error(`Unable to download, server returned ${response.status} ${response.statusText}`);
    }

    // Clone so body is not flagged as used
    const responseClone = response.clone()

    /** @type {ReadableStreamDefaultReader} */
    const reader = responseClone.body.getReader()

    const contentLength = responseClone.headers.has('Content-Length')
      ? Number.parseInt(responseClone.headers.get('Content-Length'))
      : null

    // Received that many bytes at the moment
    let receivedLength = 0

    // Simplified version without using ReadableStream as in MDN
    while (true) { // eslint-disable-line no-constant-condition
      // boolean, Uint8Array
      const { done, value: chunk } = await reader.read()

      if (done) {
        if (progressCallback) {
          progressCallback(contentLength, 100)
        }

        break
      }

      receivedLength += chunk.length

      if (progressCallback) {
        progressCallback(receivedLength, contentLength ? receivedLength / contentLength * 100 : null)
      }
    }

    // Note: need to clone reponse
    await cache.put(request, response)
  }

  /**
   * Clean up cache
   * [NOT USED]
   * TODO: check quota by `await navigator.storage.estimate()`
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Service_Worker_API/Using_Service_Workers#Deleting_old_caches
   *
   * Note: to purge all cache need to call ExpirationPlugin.deleteCacheAndMetadata in service worker
   * And code messaging API for that
   *
   * Unfortunatelly there is no public API for clean up only expired entries
   * See https://developers.google.com/web/tools/workbox/reference-docs/latest/module-workbox-expiration.ExpirationPlugin
   * @access public
   * @param {ComponentConfig} config
   * @param {BP.App.Renderer.VideoPlaylist.Options} options
   * @param {AbortSignal} abortSignal
   * @return {Promise<void>}
   */
  static async purgeCache({ cache }, options, abortSignal = undefined) { // eslint-disable-line no-unused-vars
    await Promise.all(
      options.items.map(item =>
        cache.delete(
          VideoPlaylist.createRequest(item.url || item.src)
        )
      )
    )
  }
}
