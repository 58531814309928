/**
 * Convert hard reload (network first) to soft reload (sw cache first) set by Ansible
 * @see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent}
 * @return {() => void}
 */
export default function () {
  document.addEventListener('keydown', handleKeyDown)

  return () => document.removeEventListener('keydown', handleKeyDown)
}

/**
 * Handle key down
 * @param {KeyboardEvent} keyboardEvent
 */
function handleKeyDown(keyboardEvent) {
  if (keyboardEvent.ctrlKey && keyboardEvent.key === 'F5') {
    keyboardEvent.preventDefault()
    window.location.reload()
  }
}
