import { html } from 'htm/preact'
import { useRef, useMemo, useState, useEffect } from 'preact/compat'

import { SECONDS } from '../../Constants/DateTime.js'

import { formatTextPreact } from '../../Utils/string.js'

import './Text.css'

/**
 * @typedef { import('preact').RefObject<Animation> } AnimationRef
 * @typedef { import('preact').RefObject<HTMLElement> } HTMLElementRef
 */

/** @type {Object<string, BP.App.Renderer.Text.TextAnimation>} */
const ANIMATION = {
  NONE: null,
  SLIDE_HORIZONTAL: 'slide-horizontal',
  SLIDE_VERTICAL: 'slide-vertical',
}

/** @type {Map<string, Keyframe>} - Animatin keyframes */
const animationEffects = new Map()
  .set(ANIMATION.NONE, null)
  .set(ANIMATION.SLIDE_VERTICAL, [
    { transform: 'translateY(0)' },
    { transform: 'translateY(-100%)' }
  ])
  .set(ANIMATION.SLIDE_HORIZONTAL, [
    { transform: 'translateX(0)' },
    { transform: 'translateX(-100%)' }
  ])

/**
 * Note: Chromium 72 doesn't have Animation.effect interface
 * So no Animation.effect.updateTiming or Animation.effect.setKeyframes methods
 * TODO: Improve performance on RPi 3B (18FPS)
 *
 * @param {Object} props
 * @param {BP.App.Renderer.Text.Options} props.options
 * @param {number} [props.duration] - Animation cycle duration in seconds. When null computed from text length
 * @param {number} [props.startAt] - Time ofset
 */
export default function Text({
  options: {
    text,
    animation: animationType = ANIMATION.NONE,
    animationRepeatCount = null,
    color = null,
  },
  duration,
  startAt = 0
}) {
  // Make 1 default
  animationRepeatCount = animationRepeatCount || 1

  /** @typedef {number} - Duration and playback rate */
  const animationDuration = duration / animationRepeatCount

  const rendererElement = useRef(null)
  const animationElement = useRef(null)

  /** @type {AnimationRef} */
  const animation = useRef(null)

  /** @type {Object|null} */
  const offsetStyles = useOffsetStyles(animationType, rendererElement)

  // On animation prop change
  useEffect(() => {
    // Cancel previous
    if (animation.current) {
      animation.current.cancel()
    }

    /** @type {Keyframe|null} - Picked animation effect */
    const animationEffect = animationEffects.get(animationType)

    // Skip on no animation or no keyframes
    if (!animationEffect) {
      return
    }

    // Start new animation
    // See https://developer.mozilla.org/en-US/docs/Web/API/EffectTiming
    animation.current = animationElement.current.animate(animationEffect, {
      delay: -startAt * SECONDS,
      duration: animationDuration * SECONDS,
      easing: 'linear',
      iterationStart: 0,
      // Note: Must use infinity because next slide may have all dependency props same
      // And ATM there is no way to differentiate between slides via props, even that new render is triggered
      iterations: Infinity,
    })
  }, [animationType, animationDuration, startAt])

  // On text update
  const textVnodes = useMemo(
    () => formatTextPreact(text),
    [text]
  )

  return html`
    <article
      ref=${rendererElement}
      className=${`bip-container bip-renderer bip-renderer--text bip-text-animation bip-text-animation--${(animationType)}`}
    >
      <div
        ref=${animationElement}
        className="bip-renderer--text__content"
        style=${{
          color,
          ...offsetStyles,
        }}
      >
        ${textVnodes}
      </div>
    </article>
  `
}

/**
 * Use offset styles hook
 * @param {string} animationType
 * @param {HTMLElementRef} element
 * @return {Object|null}
 */
function useOffsetStyles(animationType, element) {
  const [offsetStyles, setOffsetStyles] = useState(null)

  // Update offset styles on window resize
  useEffect(() => {
    const handleResize = debounce(
      () => setOffsetStyles(getOffsetStyles(animationType, element.current)),
      250
    )

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Update offset styles on animation type change
  useEffect(() => {
    setOffsetStyles(getOffsetStyles(animationType, element.current))
  }, [animationType])

  return offsetStyles
}

/**
 * Get element offset styles for animation
 * @param {string} animationType
 * @param {HTMLElement|null} element
 * @return {Object}
 */
function getOffsetStyles(animationType, element) {
  if (!element) {
    return null
  }

  const offsetStyles = {}

  switch (animationType) {
    case ANIMATION.SLIDE_VERTICAL:
      offsetStyles.paddingTop = `${element.clientHeight}px`
      break

    case ANIMATION.SLIDE_HORIZONTAL:
      offsetStyles.paddingLeft = `${element.clientWidth}px`
      break
  }

  return offsetStyles
}

/**
 * Debounce
 * @param {function} callback
 * @param {number} wait
 * @return {function(...any): any}
 * @link {https://chrisboakes.com/how-a-javascript-debounce-function-works/}
 */
function debounce(callback, wait) {
  let timeout

  return (...args) => {
    window.clearTimeout(timeout)

    timeout = window.setTimeout(
      callback.bind(this, ...args),
      wait
    )
  }
}
