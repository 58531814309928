import { html } from 'htm/preact'

/**
 * Fatal error, report is not sent to Sentry
 * Used by components:
 * - App: setFatalError
 * - Renderers: Item validation, other errors
 * @param {Object} props
 * @param {Error[]} props.items
 */
export default function FatalError({
  items = []
}) {
  return html`
    <article
      className="bip-container bip-fatal-error bip-fatal-error--fatal-error"
      hidden=${!items.length}
    >
      <h1 className="bip-error-title">
        ${'Error'}
      </h1>
      <ul>
        ${items.map(error =>
          html`
            <li>
              ${error.name}: ${error.message}
            </li>
          `
        )}
      </ul>
    </article>
  `
}
