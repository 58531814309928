import { useEffect, useState, useRef } from 'preact/compat'
import { html } from 'htm/preact'

import { SECONDS } from '../../Constants/DateTime.js'

import { AppError } from '../../Utils/Error.js'
import FatalErrorComponent from '../FatalError.js'

import './Clock.css'

/**
 * @typedef { import('preact').RefObject<Number> } NumberRef
 */

/**
 * Clock renderer
 * @param {Object} props
 * @param {BP.App.Renderer.Clock.Options} props.options
 */
export default function Clock({
  options: {
    locale = 'pl',
    options = {
      // Time (same as `timeStyle: 'medium',` which is only available in Chrome)
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
    },
  } = {}
}) {
  let localeNotAvailableError

  // Check locale availability
  // Note: apparently N/A in Android WebView
  /** @throws {RangeError} */
  if (locale && !Intl.DateTimeFormat.supportedLocalesOf(locale).length) {
    localeNotAvailableError = new AppError(`Locale ${locale} not available`)
  }

  const [date, setDate] = useState(() => new Date)
  const timeoutIdRef = useRef()

  const intlDateTimeFormatter = new Intl.DateTimeFormat(locale, options)

  // Mount/ Unmount
  useEffect(() => {
    updateDateInInterval(timeoutIdRef)

    return () => {
      timeoutIdRef.current && window.clearTimeout(timeoutIdRef.current)
      timeoutIdRef.current = undefined
    }

    /**
     * Update date in interval
     * @param {NumberRef} timeoutIdRef
     */
    function updateDateInInterval(timeoutIdRef) {
      setDate(new Date)

      timeoutIdRef.current = window.setTimeout(
        updateDateInInterval,
        // Self-adjust timer by computing remaining time to next second
        SECONDS - Date.now() % SECONDS,
        // Pass on ref
        timeoutIdRef
      )
    }
  }, [])

  // Show error message
  if (localeNotAvailableError) {
    return html`
      <${FatalErrorComponent} items=${[localeNotAvailableError]} />
    `
  }

  return html`
    <article className="bip-container bip-renderer bip-renderer--clock">
      <div className="bip-renderer--clock__value">
        ${date && intlDateTimeFormatter.format(date) }
      </div>
    </article>
  `
}
