/**
 * App error
 */
export class AppError extends Error {
  /**
   * @inheritdoc
   * @param {string} message
   */
  constructor(message) {
    super(message)

    // Ensure the name of this error is the same as the class name
    this.name = 'AppError'

    // Must set to work with uglification
    this.message = message

    // This clips the constructor invocation from the stack trace.
    // Not available in Firefox
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = (new Error(message)).stack
    }
  }
}

/**
 * Connection/ Network error
 */
export class ConnectionError extends AppError {
  /**
   * @inheritdoc
   * @param {string} [message]
   * @param {Request} [request]
   */
  constructor(message = 'No connection', request = undefined) {
    super(message)

    this.name = 'ConnectionError'

    /** @type {Request} */
    this.request = request
  }
}

/**
 * API Error
 */
export class ApiError extends ConnectionError {
  /**
   * Constructor
   * @param {Response} response
   * @param {Request} [request]
   */
  constructor(response, request = undefined) {
    super(response.statusText, request)

    this.name = 'ApiError'

    /** @type {Response} */
    this.response = response

    /** @type {Request} */
    this.request = request
  }
}

/**
 * Playlist error
 */
export class PlaylistError extends AppError {}
