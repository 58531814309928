import { html } from 'htm/preact'

import GuidelinesComponent from './Toolbar/Guidelines.js'
import FullscreenComponent from './Toolbar/Fullscreen.js'
import PixelateComponent from './Toolbar/Pixelate.js'
import PackageComponent from './Toolbar/Package.js'

import OVERLAY from '../Constants/Overlay.js'

import './Toolbar.css'

/**
 * Toolbar
 * @param {Object} props
 * @param {string[]} props.overlays
 * @param {Object} props.package
 * @param {function} [props.toggleOverlay]
 */
export default function Toolbar(
  props = {
    overlays: [],
    package: {},
    toggleOverlay: () => {},
  }
) {
  return html`
    <nav className="bip-toolbar">
      <${GuidelinesComponent}
        isActive=${props.overlays.includes(OVERLAY.GUIDELINES)}
        toggleActive=${() => props.toggleOverlay(OVERLAY.GUIDELINES)}
      />
      <${PixelateComponent}
        isActive=${props.overlays.includes(OVERLAY.PIXELATE)}
        toggleActive=${() => props.toggleOverlay(OVERLAY.PIXELATE)}
      />
      <${FullscreenComponent}
      />
      <${PackageComponent}
        ...${props.package}
      />
    </nav>
  `
}
