import AbstractApi from './AbstractApi.js'

/**
 * [Airly API]{@link https://developer.airly.org/}
 */
export default class AirlyApi extends AbstractApi {
  /**
   * @inheritdoc
   */
  constructor() {
    super({ baseUrl: 'https://airapi.airly.eu' })
  }

  /**
   * @inheritdoc
   * @param {string} path
   * @param {AbortSignal} [signal]
   * @param {string} [apiKey]
   * @return {Promise<any>}
   */
  async get(path, signal = undefined, apiKey = undefined) {
    const url = new URL(path, this.options.baseUrl)

    const request = new Request(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'apikey': apiKey,
      },
      signal,
    })

    try {
      const response = await fetch(request)
      return AbstractApi.decodeResponse(response)
    } catch (error) {
      AbstractApi.handleError(error, request)
    }
  }
}
