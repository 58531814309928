import { Component } from 'preact'
import { html } from 'htm/preact'

import { MINUTES } from '../../Constants/DateTime.js'

import { noop as onTaskNoop } from '../Progress.js'
import { BiApi } from '../../Utils/Api/index.js'

import {
  getIndicatorClassName,
  getIndicatorDisplayLabel,
} from './BiStation/helpers.js'

import './Station/Stations.css'
import './Station/indicator.css'
import './BiStation/indicator-aqi.css'

/**
 * @typedef { import('./BiStation/Service.js').default } Service
 * @typedef { import('./BiStation/Service.js').BiStationSensor } BiStationSensor
 * @typedef { import('./BiStation/Service.js').Measurement } Measurement
 */

/**
 * @typedef {Object} ComponentProps
 * @property {BP.App.Renderer.BiStations.Options} options
 * @property {{service: Service}} config
 * @property {BP.App.Tasks.handle} onTask
 */

/**
 * Beskid Instruments stations renderer
 * @extends {Component<ComponentProps>}
 */
export default class BiStations extends Component {
  /**
   * @inheritdoc
   * @return {ComponentProps}
   */
  static get defaultProps() {
    return {
      options: {
        items: [],
        title: null,
        messages: [],
      },
      config: { service: undefined },
      onTask: onTaskNoop,
    }
  }

  /**
   * Constructor
   * @param {ComponentProps} props
   */
  constructor(props) {
    super(props)

    this.state = {
      /** @type {BiStationSensor[]} */
      stationSensors: [],
      /** @type {Measurement[]} */
      measurements: [],
      /** @type {string} */
      dateString: null,
    }

    /** @type {Number|undefined} */
    this.refreshIntervalId = undefined

    /** @type {Number|undefined} */
    this.updateDateTimeoutId = undefined

    /** @type {Map<string, AbortController>} */
    this.abortController = new Map()

    /** @type {Intl.DateTimeFormat} - d/m/Y G:i */
    this.intlDateTimeFormatter = new Intl.DateTimeFormat('pl', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
    })

    /** @type {Boolean} - Unmounting flag */
    this.isUnmounting = false
  }

  /**
   * @inheritdoc
   */
  async componentDidMount() {
    await this.updateDataFromCache()

    const updateTaskId = (!this.state.stationSensors.length)
      ? this.props.onTask()
      : undefined

    // Start data updater
    await this.updateData()

    // Start clock updater
    await this.updateClock()

    updateTaskId && this.props.onTask(updateTaskId)
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} prevProps
   */
  async componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.options.items) !== JSON.stringify(this.props.options.items)) {
      await this.updateDataFromCache()
      await this.updateData()
    }
  }

  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    this.refreshIntervalId && window.clearTimeout(this.refreshIntervalId)
    this.updateDateTimeoutId && window.clearTimeout(this.updateDateTimeoutId)
    this.isUnmounting = true

    this.abortController.forEach(BiApi.abort)
    this.abortController.clear()
  }

  /**
   * Update data from cache with fall back to initial state
   * @access protected
   * @return {Promise<void>}
   */
  async updateDataFromCache() {
    const { service } = this.props.config

    /** @type {Object} */
    const newState = {}

    // Get cached sensors when empty
    if (!this.state.stationSensors.length) {
      newState.stationSensors = await service.getCachedStationSensors(true, [])
    }

    // Get cached measurements
    newState.measurements = await Promise.all(
      this.props.options.items.map(stationOptions =>
        service.getCachedMeasurement(stationOptions, true, {})
      )
    )

    // Note: this adds undefined measurements to array by purpose
    await new Promise(resolve => this.setState(newState, () => resolve()))
  }

  /**
   * Request data, update state and start timeout
   * @access protected
   * @return {Promise<void>}
   */
  async updateData() {
    const { service } = this.props.config

    // Stop previous timeout
    this.refreshIntervalId && window.clearTimeout(this.refreshIntervalId)
    this.refreshIntervalId = undefined

    // Abort running requests
    this.abortController.forEach(BiApi.abort)
    this.abortController.clear()

    // Station sensors
    this.abortController.set('stationSensors', new AbortController())

    /** @type {BiStationSensor[]|undefined} */
    let stationSensors

    try {
      stationSensors = await service.getStationSensors(this.abortController.get('stationSensors').signal)
    } catch (error) {
      // Noop
    } finally {
      this.abortController.delete('stationSensors')
    }

    if (this.isUnmounting) {
      return
    }

    if (stationSensors) {
      await new Promise(resolve => this.setState({ stationSensors }, () => resolve()))
    }

    // Measurements (concurrent promise resolution)
    await Promise.all(this.props.options.items.map(this.updateMeasurement, this))

    if (this.isUnmounting) {
      return
    }

    // Update measurements in interval
    this.refreshIntervalId = window.setTimeout(
      () => this.updateData(),
      service.getMeasurementsRefreshInterval()
    )
  }

  /**
   * Update measurement
   * Note: Doesn't abort previous requests
   * @access protected
   * @param {BP.App.Renderer.BiStation.Options} stationOptions
   * @param {Number} index
   * @return {Promise<void, void>}
   */
  async updateMeasurement(stationOptions, index) {
    /** @type {Measurement} */
    let measurement

    const abortController = new AbortController()
    const abortControllerKey = `measurement:${stationOptions.id}`

    // Add abort controller
    this.abortController.set(abortControllerKey, abortController)

    try {
      measurement = await this.props.config.service.getMeasurement(stationOptions, abortController.signal)
    } catch (error) {
      // Note: Do not throw on error to not stop Promise.all
      return
    } finally {
      this.abortController.delete(abortControllerKey)
    }

    if (this.isUnmounting) {
      return
    }

    // Update measurement in state
    const measurements = this.state.measurements.map((cmpMeasurement, cmpIndex) =>
      index === cmpIndex
        ? measurement
        : cmpMeasurement
    )

    return new Promise(resolve => this.setState({ measurements }, resolve))
  }

  /**
   * Update clock in 1m interval at full minute
   * @return {Promise<void>}
   */
  async updateClock() {
    this.updateDateTimeoutId && window.clearTimeout(this.updateDateTimeoutId)
    this.updateDateTimeoutId = undefined

    const dateString = BiStations.formatDate(new Date(), this.intlDateTimeFormatter)

    await new Promise(resolve => this.setState({ dateString }, () => resolve()))

    this.updateDateTimeoutId = window.setTimeout(
      () => this.updateClock(),
      MINUTES - Date.now() % MINUTES
    )
  }

  /**
   * @inheritdoc
   */
  render() {
    // Handle data fetch error
    if (!this.state.stationSensors.length || !this.state.measurements) {
      return html`
        <article className="bip-container bip-renderer bip-renderer--bi-station">
          ${'No data'}
        </article>
      `
    }

    // Compute cols length (sensors with norm take 2 cols)
    const stationSensorsColsLength = this.state.stationSensors.reduce((acc, stationSensor) =>
      acc + (stationSensor.hasNorm ? 2 : 1),
      0
    )

    return html`
      <article className="bip-container bip-renderer bip-renderer--bi-stations">
        <table className="bip-stations-table">
          <thead>
            <!-- Title -->
            ${this.props.options.title && html`
              <tr>
                <td
                  className="bip-stations-caption"
                  colSpan=${stationSensorsColsLength + 2}
                >
                  ${this.props.options.title}
                </td>
              </tr>
            `}
            <!-- Station sensors -->
            <tr className="bip-stations-heading">
              <th rowSpan="2">
                ${'Lokalizacja/ Pomiar'}
              </th>
              ${this.state.stationSensors.map(stationSensor => html`
                <th
                  className="bip-stations-header"
                  colSpan=${stationSensor.hasNorm && 2}
                >
                  ${stationSensor.label}
                </th>
              `)}
              <th rowSpan="2">
                ${'Stan'}
              </th>
            </tr>
            <tr>
              ${this.state.stationSensors.map(stationSensor => html`
                <td className="bip-stations-header bip-stations-header--sensor-unit">
                  ${stationSensor.unitSymbol}
                </td>
                ${stationSensor.hasNorm && html`
                  <td className="bip-stations-header bip-stations-header--sensor-unit">
                    %
                  </td>
                `}
              `)}
            </tr>
          </thead>
          <!-- Stations -->
          <tbody>
            ${this.state.measurements.map(measurement => html`
              <tr>
                <!-- Label -->
                <th className="bip-stations-station-label">
                  ${measurement.label || '-'}
                </th>
                <!-- Measurements -->
                ${measurement.valuesMap.size
                  ? this.state.stationSensors.map(stationSensor => html`
                    <td className="bip-stations-station-value">
                      ${(measurement.valuesMap.has(stationSensor.code) && measurement.valuesMap.get(stationSensor.code).value !== null)
                        ? measurement.valuesMap.get(stationSensor.code).value.toFixed(0)
                        : '-'
                      }
                    </td>
                    ${stationSensor.hasNorm && html`
                      <td className="bip-stations-station-value">
                        ${(measurement.valuesMap.has(stationSensor.code) && measurement.valuesMap.get(stationSensor.code).normPercent !== null)
                          ? measurement.valuesMap.get(stationSensor.code).normPercent.toFixed(0)
                          : '-'
                        }
                      </td>
                    `}
                  `)
                  : html`
                    <td colSpan=${stationSensorsColsLength}>
                      -
                    </td>
                    `
                }
                <!-- Indicator -->
                <td className="bip-stations-indicator">
                  <span
                    className=${`station-indicator station-indicator--small aqi-pl ${getIndicatorClassName(measurement.aqiPl)}`}
                  ></span>
                  <span>
                    ${getIndicatorDisplayLabel(measurement.aqiPl)}
                  </span>
                </td>
              </tr>
            `)}
          </tbody>
          <tfoot>
            <tr>
              <td className="bip-stations-clock">
                ${this.state.dateString}
              </td>
              <td
                className="bip-stations-animation"
                colSpan=${stationSensorsColsLength + 1}
              >
                <div className="bip-stations-animation__content">
                  ${this.props.options.messages.map(message => html`
                    <span>
                      ${message}
                    </span>
                  `)}
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </article>
    `
  }

  /**
   * Format to date and time
   * @access protected
   * @param {Date} date
   * @param {Intl.DateTimeFormat} dateTimeFormat
   * @return {string}
   */
  static formatDate(date, dateTimeFormat) {
    return dateTimeFormat.format(date)
  }

  /**
   * Format to date and time in d/m/Y G:i
   * @access protected
   * @deprecated
   * @param {Date} date
   * @param {Intl.DateTimeFormat} dateTimeFormat
   * @return {string}
   */
  static formatDateFunkyFormat(date, dateTimeFormat) {
    const map = new Map()

    for (const { type, value } of dateTimeFormat.formatToParts(date)) {
      if (type !== 'literal') {
        map.set(type, value)
      }
    }

    return `${map.get('day')}/${map.get('month')}/${map.get('year')} ${map.get('hour')}:${map.get('minute')}`
  }

  /**
   * Populate cache items
   * @access public
   * @param {ComponentProps['config']} config
   * @param {BP.App.Renderer.BiStations.Options} options
   * @return {Promise<void, Error>}
   */
  static populateCache({ service }, options) {
    return service.populateCacheItems(options)
  }

  /**
   * Clean up cache
   * @access public
   * @param {ComponentProps['config']} config
   * @return {Promise<void>}
   */
  static purgeCache({ service }) {
    return service.purgeCache()
  }
}
