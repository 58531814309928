/**
 * Prefetch strategy
 * Note: Nollup problem when using export const
 * @enum {string}
 */
const PREFETCH = {
  // Use source attribute
  NONE: 'none',
  // Preload from local cache as blob
  LOCAL_CACHE: 'local-cache',
  // Preload from network (or service worker) as blob
  NETWORK: 'network',
}

export { PREFETCH }
