import AbstractApi from './AbstractApi.js'

/**
 * Syngeos API
 */
export default class SyngeosApi extends AbstractApi {
  /**
   * @inheritdoc
   */
  constructor() {
    super({ baseUrl: 'https://api.syngeos.pl/api' })
  }
}
