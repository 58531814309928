import { useEffect, useState } from 'preact/compat'
import { html } from 'htm/preact'

import { SECONDS } from '../../Constants/DateTime.js'

import { AppError } from '../../Utils/Error.js'
import FatalErrorComponent from '../FatalError.js'

import './Countdown.css'

/**
 * @typedef {Object} TimeSpan
 * @property {number} total
 * @property {number} miliseconds
 * @property {number} seconds
 * @property {number} minutes
 * @property {number} hours
 * @property {number} days
 */

/**
 * Countdown renderer
 * Inspiration: https://getuikit.com/docs/countdown#label
 *              https://github.com/uikit/uikit/blob/develop/src/js/components/countdown.js
 *              https://codepen.io/shshaw/pen/vKzoLL
 * Note: Intl.RelativeTimeFormat formatToParts doesn't fit
 * @param {Object} props
 * @param {BP.App.Renderer.Countdown.Options} props.options
 */
export default function Countdown({
  options: {
    dateTimestamp,
    text = undefined,
    unitsTranslated = {
      seconds: 'sekund',
      minutes: 'minut',
      hours:   'godzin',
      days:    'dni',
    },
  }
}) {
  let dateTimestampError

  if (!dateTimestamp) {
    dateTimestampError = new AppError('Invalid Date')
  }

  const units = ['days', 'hours', 'minutes', 'seconds']
  const [timeSpan, setTimeSpan] = useState(() => getTimeSpan(dateTimestamp))

  // Mount/ Unmount
  useEffect(() => {
    const intervalId = setInterval(
      () => setTimeSpan(getTimeSpan(dateTimestamp)),
      1 * SECONDS
    )

    return () => clearInterval(intervalId)
  }, [])

  if (!dateTimestamp) {
    return html`
      <${FatalErrorComponent}
        items=${[dateTimestampError]}
      />
    `
  }

  return html`
    <article className="bip-container bip-renderer bip-renderer--countdown">
      <div className="bip-countdown-value">
        ${units.map(unit => html`
          <span className="bip-countdown">
            <span className=${`bip-countdown-number bip-countdown-unit--${unit}`}>
              ${timeSpan && Math.floor(timeSpan[unit]).toString().padStart(2, '0')}
            </span>
            <span className=${`bip-countdown-label bip-countdown-label--${unit}`}>
              ${unitsTranslated[unit] || unit}
            </span>
          </span>
        `)}
      </div>
      ${text && html`
        <p className="bip-countdown-description">
          ${text}
        </p>
      `}
    </article>
  `
}

/**
 * Get time span
 * @param {number} dateTs - Date as an Unix timestamp
 * @return {TimeSpan}
 */
function getTimeSpan(dateTs) {
  const total = Math.max(dateTs * 1e3 - Date.now(), 0)

  return {
    total,
    miliseconds: total % 1e3,
    seconds:     total / 1e3 % 60,
    minutes:     total / 1e3 / 60 % 60,
    hours:       total / 1e3 / 60 / 60 % 24,
    days:        total / 1e3 / 60 / 60 / 24,
  }
}
