import { h } from 'preact'

/**
 * Format text by wrapping
 * - `_` with `.bip-text-fragment-emphasis`
 * Note: Mixing patterns does not work
 * @see https://github.com/Chalarangelo/parse-md-js/blob/master/parsemd.js
 * @param {string} input
 * @return {preact.VNode|preact.VNode[]}
 */
export function formatTextPreact(
  input,
  replacements = {
    '_': 'bip-text-fragment-emphasis',
    '**': 'bip-text-fragment-bold',
    // '\n': 'bip-text-fragment-block',
  },
  defaultClassName = 'bip-text-fragment-normal',
) {
  // Note: may compile that
  const regExpPatterns = Object.keys(replacements).map(escapeRegExp)
  const fragmentRegexp = new RegExp(
    `(${regExpPatterns.join('|')})([^\\1]+)\\1`,
    'gm'
  )

  // Note: pattern precedes every fragment to be replaced
  const fragments = input
    .split(fragmentRegexp)
    // Remove empty strings
    .filter(fragment => fragment)

  const output = []

  for (let i = 0; i < fragments.length; i++) {
    // It's a pattern, next one is text fragment
    const className = fragments[i] in replacements
      // Use configured class name and shift index
      ? replacements[fragments[i++]]
      : defaultClassName

    // Nest
    const children = fragmentRegexp.test(fragments[i])
      ? formatTextPreact(fragments[i], replacements, defaultClassName)
      : fragments[i]

    output.push(h(
      'span',
      { className },
      children
    ))
  }

  return output
}

/**
 * Escape string to use with RegExp
 * @see https://stackoverflow.com/a/6969486/1012616
 * @param {string} string
 * @return {string}
 */
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}
