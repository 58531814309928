import { ConnectionError, ApiError } from '../Error.js'

/**
 * Abstract API
 */
export default class AbstractApi {
  /**
   * Constructor
   * @param {BP.App.AbstractApi.Options} options
   */
  constructor(options) {
    // Prepend protocol if missing
    if (options.baseUrl.startsWith('//')) {
      options.baseUrl = window.location.protocol + options.baseUrl
    }

    this.options = {
      baseUrl: null,
      ...options,
    }
  }

  /**
   * Get
   * @access public
   * @param {string} path
   * @param {AbortSignal} signal
   * @return {Promise<any, Error>}
   */
  async get(path, signal = undefined) {
    const url = `${this.options.baseUrl}/${path}`

    const request = new Request(url, {
      method: 'GET',
      cache: 'no-cache',
      signal,
    })

    try {
      const response = await fetch(request)
      return AbstractApi.decodeResponse(response, request)
    } catch (error) {
      AbstractApi.handleError(error, request)
    }
  }


  /**
   * Decode response
   * @access protected
   * @param {Response} response
   * @param {Request} [request]
   * @return {Promise<any, SyntaxError|ApiError>}
   */
  static async decodeResponse(response, request = undefined) {
    // Note: may pass on api error details when using ApiProblem
    if (!response.ok) {
      throw new ApiError(response, request)
    }

    /** @throws {SyntaxError} */
    return response.json()
  }

  /**
   * Handle error
   * @access protected
   * @param {SyntaxError|ApiError|Error} error
   * @param {Request} request
   * @throws {Error} - Rethrow error so it's catchable
   */
  static handleError(error, request = undefined) {
    switch (error.name) {
      // Pass on abort error / DOMException (AbortError: The user aborted a request.)
      case 'AbortError':
        throw error

      // Connection error or abort (TypeError: Failed to fetch)
      case TypeError.name:
        throw new ConnectionError('No connection', request)

      // Error in API response ('Unexpected token < in JSON at position 0')
      case SyntaxError.name:
        throw new ConnectionError('Invalid response', request)

      // Other
      case ApiError.name:
      default:
        throw error
    }
  }

  /**
   * Abort abort controller if not aborted yet
   * @access public
   * @param {AbortController|null} abortController
   */
  static abort(abortController) {
    if (
      !abortController ||
      abortController.signal.aborted
    ) {
      return
    }

    abortController.abort()
  }
}
