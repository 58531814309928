export const MILISECONDS = 1
export const SECONDS = 1000 * MILISECONDS
export const MINUTES = 60 * SECONDS
export const HOURS = 60 * MINUTES
export const DAYS = 24 * HOURS

export default {
  MILISECONDS,
  SECONDS,
  MINUTES,
  HOURS,
  DAYS,
}
