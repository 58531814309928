import { Component } from 'preact'
import { html } from 'htm/preact'

// Constants
import CONTENT_TYPE from '../Constants/ContentType.js'
import { DAYS, HOURS, MINUTES, SECONDS } from '../Constants/DateTime.js'
import { PREFETCH } from './Renderer/Video/Constants.js'

// Utils
import { PlaylistError } from '../Utils/Error.js'
import compileItems from './Playlist/compileItems.js'
import { AirlyApi } from '../Utils/Api/index.js'

// Renderer components
// TODO: evaluate dynamic imports
import AirlyStationRenderer from './Renderer/AirlyStation.js'
import BiStationRenderer from './Renderer/BiStation.js'
import BiStationsRenderer from './Renderer/BiStations.js'
import ClockRenderer from './Renderer/Clock.js'
import CountdownRenderer from './Renderer/Countdown.js'
import FallbackRenderer from './Renderer/Fallback.js'
import ImageRenderer from './Renderer/Image.js'
import ScreensaverRenderer from './Renderer/Screensaver.js'
import SyngeosStationRenderer from './Renderer/SyngeosStation.js'
import SyngeosStationsRenderer from './Renderer/SyngeosStations.js'
import TextRenderer from './Renderer/Text.js'
import VideoRenderer from './Renderer/Video/Video.js'
import VideoPlaylistRenderer from './Renderer/VideoPlaylist.js'
import WebsiteRenderer from './Renderer/Website.js'
import YoutubePlaylistRenderer from './Renderer/YoutubePlaylist.js'

import AirlyStationService from './Renderer/AirlyStation/Service.js'
import SyngeosStationService from './Renderer/SyngeosStation/Service.js'
import BiStationService from './Renderer/BiStation/Service.js'

import './Playlist.css'

/**
 * @typedef { import('preact').ComponentClass } ComponentClass
 * @typedef { import('preact').FunctionComponent } FunctionComponent
 * @typedef { import('../Utils/Api').BiApi } BiApi
 * @typedef { import('../Utils/Api').SyngeosApi } SyngeosApi
 * @typedef { import('../Utils/ClientHints').default} ClientHints
 */

/**
 * @typedef {(config: Object, options: BP.App.Renderer.BaseOptions) => Promise<void, Error>} populateCacheFunction
 */

/**
 * @typedef {Object} RendererDefinition
 * @property {ComponentClass & { populateCache?: populateCacheFunction } | FunctionComponent & { populateCache?: populateCacheFunction }} Component
 * @property {Object} [config]
 */

/**
 * @typedef {Object} ComponentProps
 * @property {BP.App.Content.Playlist} playlist
 * @property {BP.App.RuntimeCachingOptions} runtimeCaching
 * @property {BP.App.Options["useServiceWorker"]} useServiceWorker
 * @property {BiApi} biApi
 * @property {SyngeosApi} syngeosApi
 * @property {ClientHints} clientHints
 * @property {BP.App.PackageMetadata} package
 * @property {BP.App.Tasks.handle} handleTask
 */

/**
 * @typedef {Object} ComponentState
 * @property {BP.App.Content.PlaylistItem[]} items
 * @property {number|null} currentItemIndex - Current item index
 * @property {number} currentItemStartAt - Current item time offset
 * @property {boolean} isScreenSaverActive
 * @property {boolean} didMount - Mounted flag, used in async componentDidMount

/**
 * @typedef {Object} CurrentItemInfo
 * @property {number|null} index - Index in playlist
 * @property {number|undefined} duration - Duration in seconds
 */

/**
 * @typedef {Object} ScreenSaverRange
 * @property {string} start
 * @property {string} end
 */

/**
 * Playlist
 * @extends {Component<ComponentProps, ComponentState>}
 */
export default class Playlist extends Component {
  /**
   * @inheritdoc
   * @return {ComponentProps}
   */
  static get defaultProps() {
    return {
      playlist: {
        items: [],
        screenSaverRange: null,
      },
      runtimeCaching: undefined,
      useServiceWorker: false,
      biApi: undefined,
      syngeosApi: undefined,
      clientHints: undefined,
      package: undefined,
      handleTask: undefined,
    }
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} props
   */
  constructor(props) {
    super(props)

    // Note: may also add cutout duration and offset
    /** @type {ComponentState} */
    this.state = {
      items: compileItems(this.props.playlist.items),
      currentItemIndex: null,
      currentItemStartAt: 0,
      isScreenSaverActive: false,
      didMount: false,
    }

    /** @type {Map<BP.App.Content.Type, RendererDefinition>} */
    this.renderers = new Map()

    /** @type {number|null} */
    this.handleItemPlayEndTimeoutId = null

    /** @type {number|null} */
    this.handleScreenSaverToggleTimeoutId = null

    // Bind events
    this.handleItemPlayEnd = this.handleItemPlayEnd.bind(this)
  }

  /**
   * @inheritdoc
   */
  async componentDidMount() {
    const mountTaskId = this.props.handleTask()

    // Note: False positive on Firefox Privacy mode which doesn't support Cache and IndexedDB
    const isTrustedOrigin = (
      window.location.protocol === 'https:' ||
      window.location.hostname === 'localhost'
    )

    const airlyStationService = await new AirlyStationService(new AirlyApi(), {
      cacheDbName: `${this.props.package.name}/renderer/airly-station`
    }).initialize()

    const biStationsService = await new BiStationService(this.props.biApi, {
      cacheDbName: `${this.props.package.name}/renderer/bi-station`
    }).initialize()

    const syngeosStationService = await new SyngeosStationService(this.props.syngeosApi, {
      cacheDbName: `${this.props.package.name}/renderer/syngeos-station`
    }).initialize()

    // Note: CacheStorage is N/A on untrusted origins
    const videoCache = isTrustedOrigin
      ? await caches.open(this.props.runtimeCaching.video.options.cacheName)
      : undefined

    // Note: TS doesn't like passing array of different ComponentFactories in constructor
    this.renderers
      .set(CONTENT_TYPE.AIRLY_STATION,    { Component: AirlyStationRenderer, config: { service: airlyStationService } })
      .set(CONTENT_TYPE.BI_STATION,       { Component: BiStationRenderer, config: { service: biStationsService } })
      .set(CONTENT_TYPE.BI_STATIONS,      { Component: BiStationsRenderer, config: { service: biStationsService } })
      .set(CONTENT_TYPE.CLOCK,            { Component: ClockRenderer })
      .set(CONTENT_TYPE.COUNTDOWN,        { Component: CountdownRenderer })
      .set(CONTENT_TYPE.FALLBACK,         { Component: FallbackRenderer })
      .set(CONTENT_TYPE.SCREENSAVER,      { Component: ScreensaverRenderer })
      .set(CONTENT_TYPE.SYNGEOS_STATION,  { Component: SyngeosStationRenderer, config: { service: syngeosStationService } })
      .set(CONTENT_TYPE.SYNGEOS_STATIONS, { Component: SyngeosStationsRenderer, config: { service: syngeosStationService } })
      .set(CONTENT_TYPE.IMAGE, {
        Component: ImageRenderer,
        config: {
          clientHints: this.props.clientHints,
          // Note: May move to cache initialization to precachePlaylist
          cache: isTrustedOrigin
            ? await caches.open(this.props.runtimeCaching.image.options.cacheName)
            : undefined
        }
       })
      .set(CONTENT_TYPE.TEXT,             { Component: TextRenderer })
      .set(CONTENT_TYPE.WEBSITE,          { Component: WebsiteRenderer })
      .set(CONTENT_TYPE.YOUTUBE_PLAYLIST, { Component: YoutubePlaylistRenderer })
      .set(CONTENT_TYPE.VIDEO, {
        Component: VideoRenderer,
        config: isTrustedOrigin
          ? {
              cache: videoCache,
              prefetch: this.props.useServiceWorker
                ? PREFETCH.NETWORK
                : PREFETCH.LOCAL_CACHE,
            }
          : undefined
      })
      .set(CONTENT_TYPE.VIDEO_PLAYLIST, {
        Component: VideoPlaylistRenderer,
        config: isTrustedOrigin
          ? {
              cache: videoCache,
              manualCacheRead: this.props.useServiceWorker ? false : true,
            }
          : undefined
      })

    this.setState({ didMount: true }, () =>
      this.props.handleTask(mountTaskId)
    )
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} prevProps
   * @param {ComponentState} prevState
   */
  async componentDidUpdate(prevProps, prevState) {
    /**
     * Check that component fully mounted before starting playlist (some renderers initialize in async)
     * ...as playlist could have been updated during mount (on slow devices)
     */
    if (
      this.state.didMount &&
      (
        // Ignore empty arrays
        prevProps.playlist.items.length && this.props.playlist.items.length &&
        // First mount
        !prevState.didMount ||
        // Playlist updated
        prevProps.playlist.items !== this.props.playlist.items
      )
    ) {
      // Compile items from props
      this.setState({
        items: compileItems(this.props.playlist.items)
      })
    }

    // Play list
    if (this.state.items !== prevState.items) {
      this.playList()
    }

    // Toggle screen savero
    if (prevProps.playlist.screenSaverRange !== this.props.playlist.screenSaverRange) {
      this.stopScreenSaverToggleHandler()

      if (!this.props.playlist.screenSaverRange) {
        this.state.isScreenSaverActive && this.setState({ isScreenSaverActive: false })
      } else {
        this.startScreenSaverToggle()
      }
    }
  }

  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    this.stopItemPlayEndHandler()
    this.stopScreenSaverToggleHandler()

    // TODO: abort playlist prefetch
  }

  /**
   * Play whole list
   * @access protected
   */
  async playList() {
    // Precache playlist (await lost in #7ce7746e04)
    await this.precachePlaylist()

    // Stop previous playback
    this.stopItemPlayEndHandler()

    // Play current item
    this.playCurrentItem(true)
  }

  /**
   * Play item that should be played at this moment, scheduling itself at the end of duration
   * @access protected
   * @param {boolean} [useOffset] - Use offset (first item)
   */
  playCurrentItem(useOffset = false) {
    // Determine which item to play and for how long
    const {
      index,
      duration
    } = Playlist.createCurrentItemInfo(
      this.state.items,
      (new Date).getTimezoneOffset() * MINUTES
    )

    // Ignore on startup render with null index
    if (
      index === null &&
      index === this.state.currentItemIndex
    ) {
      return
    }

    /** @type {BP.App.Content.PlaylistItem|null|undefined} */
    const item = index !== null
      ? this.state.items[index]
      : null

    if (item === undefined) {
      throw new PlaylistError(`Out of range: ${index}`)
    }

    // Note: between 1..15ms
    this.setState({
      currentItemIndex: index,
      currentItemStartAt: useOffset && item
        ? item.duration - duration
        : 0
    })

    // Do not schedule end
    if (!item || !duration || this.state.items.length === 1) {
      return
    }

    // Schedule next one
    this.handleItemPlayEndTimeoutId = window.setTimeout(
      this.handleItemPlayEnd,
      duration * SECONDS
    )
  }

  /**
   * Toggle
   * @access protected
   * @return {void}
   */
  startScreenSaverToggle() {
    const now = new Date()
    const nowTime = now.getHours() * HOURS + now.getMinutes() * MINUTES + now.getSeconds() * SECONDS

    let startTime = Playlist.getScreenSaverTime(this.props.playlist.screenSaverRange.start)
    let endTime = Playlist.getScreenSaverTime(this.props.playlist.screenSaverRange.end)

    // Range spans over midnight
    if (endTime < startTime) {
      endTime += 1 * DAYS
    }

    const isScreenSaverActive = nowTime >= startTime && nowTime < endTime

    this.setState({ isScreenSaverActive })

    const toggleTime = isScreenSaverActive
      ? endTime
      : startTime

    this.handleScreenSaverToggleTimeoutId = window.setTimeout(
      () => this.startScreenSaverToggle(),
      (toggleTime - nowTime + 1 * DAYS) % (1 * DAYS)
    )
  }

  /**
   * Stop next item play
   * @access protected
   */
  stopItemPlayEndHandler() {
    if (this.handleItemPlayEndTimeoutId) {
      window.clearTimeout(this.handleItemPlayEndTimeoutId)

      this.handleItemPlayEndTimeoutId = null
    }
  }

  /**
   * Stop screensaver toggle
   * @access protected
   */
  stopScreenSaverToggleHandler() {
    if (this.handleScreenSaverToggleTimeoutId) {
      window.clearTimeout(this.handleScreenSaverToggleTimeoutId)

      this.handleScreenSaverToggleTimeoutId = null
    }
  }

  /**
   * Handle item play end (play next one)
   * @access protected
   */
  handleItemPlayEnd() {
    this.playCurrentItem()
  }

  /**
   * Find out which item should be played now and for how long
   * @access protected
   * @param {BP.App.Content.PlaylistItem[]} items
   * @param {number} [playlistStartOffset] - Start offset in ms, use to pass negative time zone offset in which playlist has been created (-2h for Europe/Warsaw DST)
   * @return {CurrentItemInfo}
   */
  static createCurrentItemInfo(items, playlistStartOffset = 0) {
    /** @type {CurrentItemInfo} */
    const info = {
      index: null,
      duration: undefined,
    }

    // Skip when playlist is empty
    if (items.length === 0) {
      return info
    }

    const currentTimeTs = Date.now()

    // Simulation: 1970-01-01 20:59:50 UTC
    // const nowTs = (21 * 60 * MINUTES) - (10 * SECONDS);

    // Compute playlist start timestamp
    const playlistStartTs = currentTimeTs - playlistStartOffset

    // Compute total playlist duration
    const playlistDuration = items.reduce((acc, item) =>
      acc + (item.duration * SECONDS),
      0
    )

    // Compute current time offset/ relative position within playlist counting from UNIX Epoch
    const currentTimeInPlaylist = playlistStartTs % playlistDuration

    /** @type {number} - Total end time current item, may also compute via this.state.items.slice(0, info.index + 1) */
    let lastIteratedItemEndAt = 0

    // Compute index and add past duration incl. found item
    info.index = items.findIndex(item =>
      (lastIteratedItemEndAt += item.duration * SECONDS) > currentTimeInPlaylist
    )

    // Compute remaining duration
    info.duration = (lastIteratedItemEndAt - currentTimeInPlaylist) / SECONDS

    return info
  }

  /**
   * @inheritdoc
   */
  render() {
    // Skip if not mount
    if (!this.state.didMount) {
      return null
    }

    if (this.state.isScreenSaverActive) {
      return html`
        <section className="bip-renderers">
          <${ScreensaverRenderer} />
        </section>
      `
    }

    // Note: works fine when index is null
    const item = this.state.items[this.state.currentItemIndex]

    const renderer = item && this.renderers.has(item.type)
      ? this.renderers.get(item.type)
      : this.renderers.get(CONTENT_TYPE.FALLBACK)

    return html`
      <section className="bip-renderers">
        ${renderer && item && html`
          <${renderer.Component}
            config=${renderer.config}
            options=${item.options || undefined}
            duration=${item.duration}
            startAt=${this.state.currentItemStartAt}
            onTask=${this.props.handleTask}
          />
        `}
      </section>
    `
  }

  /**
   * Precache playlist
   * Runs populateCache in sequence to prevent race condition (ie. caching same video item in pararell)
   * @access protected
   * @return {Promise<void>}
   */
  async precachePlaylist() {
    // Note: may create handlePromiseTask helper
    const precacheTaskId = this.props.handleTask()

    for (const item of this.state.items) {
      // Match item to renderer
      const renderer = this.renderers.get(item.type)

      // No such renderer or doesn't support precache
      if (!renderer || !renderer.Component.populateCache) {
        continue
      }

      try {
        await renderer.Component.populateCache(renderer.config, item.options)
      } catch (error) {
        // Noop: Most probably ConnectionError (offline)
      }
    }

    this.props.handleTask(precacheTaskId)
  }

  /**
   * Get time in miliseconds
   * @access protected
   * @param {string} screenSaverPeriodItem
   * @return {number}
   */
  static getScreenSaverTime(screenSaverPeriodItem) {
    const [hours, minutes = 0, seconds = 0] = screenSaverPeriodItem
      .split(':', 3)
      .map(Number.parseFloat)

    return hours * HOURS + minutes * MINUTES + seconds * SECONDS
  }
}
