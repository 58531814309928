import { useState } from 'preact/hooks'
import { html } from 'htm/preact'

import classNames from 'classnames'

/**
 * @typedef { import('preact/hooks').StateUpdater<boolean> } BooleanStateUpdater
 */

/**
 * Toggle Fullscreen
 */
export default function Fullscreen(
) {
  /** @type {[boolean, BooleanStateUpdater]} */
  const [isActive, setIsActive] = useState(() => isStartedFullscreen())

  /** @type {[boolean, BooleanStateUpdater]} - Permission denied or already in FS without ability to change */
  const [notAvailable] = useState(() => !document.fullscreenEnabled || isStartedFullscreen())

  /**
   * Handle toggle button click
   */
  const handelToggleButtonClick = () => {
    // N/A
    if (notAvailable) {
      return
    }

    // Already in fullscreen via API
    if (document.fullscreenElement) {
      document.exitFullscreen()
    } else {
      // Does not work properly, fullscreen on document.body stretches element
      document.documentElement.requestFullscreen({ navigationUI: 'hide' })
    }

    setIsActive(!isActive)
  }

  return html`
    <button
      className=${classNames('bip-button', { 'bip-button--active': isActive })}
      type="button"
      disabled=${notAvailable}
      tabindex="-1"
      onClick=${handelToggleButtonClick}
    >${'Fullscreen'}</button>
  `
}

/**
 * Check if in fullscreen mode
 * @return {boolean}
 */
function isStartedFullscreen() {
  return (
    // Check if fullscreen set by other script
    document.fullscreenElement !== null ||
    // Evaluate by comparing dimensions
    (window.innerWidth === window.screen.width && window.innerHeight === window.screen.height)
  )
}
