/**
 * Service worker handler
 * @see https://github.com/facebook/create-react-app/blob/v3.4.1/packages/cra-template/template/src/serviceWorker.js
 * @see https://github.com/cra-template/pwa/blob/cra-template-pwa%401.0.3/packages/cra-template-pwa/template/src/serviceWorkerRegistration.js
 */

import { SECONDS } from './Constants/DateTime.js'

/**
 * @typedef {Object} Config
 * @property {(registration: ServiceWorkerRegistration) => void} [onSuccess]
 * @property {(registration: ServiceWorkerRegistration) => void} [onUpdate]
 */

// Kill switch search (query) param name
const KILL_SWITCH_PARAM = 'kill-switch'

/**
 * Register
 * @param {Config} [config]
 * @return {Promise<ServiceWorkerRegistration|undefined, Error>}
 *
 * Note: CTRL+F5 doesn't check for new sw
 * Note: Browsers use 24h built-in limit for fresh service worker check
 * Note: may use workbox-window but rather for complex stuff
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Service_Worker_API#Download_install_and_activate
 * Note: When offline, extra errors come may up in console (Chrome 72) that are safe to ignore like
 * - An unknown error occurred when fetching the script. / service-worker.js:1
 * - GET https://biotic-presenter.com/service-worker.js net::ERR_INTERNET_DISCONNECTED / service-worker.js:1
 * @see https://github.com/vuejs-templates/pwa/issues/97#issuecomment-390513680
 * @see https://github.com/goldhand/sw-precache-webpack-plugin/issues/51
 * @see https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
 * @see https://github.com/facebook/create-react-app/blob/v3.4.1/packages/cra-template/template/src/serviceWorker.js#L57-L99
 * @see https://stackoverflow.com/questions/41891031/refresh-page-on-controllerchange-in-service-worker
 * @see https://developers.google.com/web/tools/workbox/modules/workbox-window
 * @see https://developers.google.com/web/tools/workbox/guides/advanced-recipes
 */
export async function register(config = {}) {
  if ('serviceWorker' in navigator === false) {
    return
  }

  if (detectForceKillSwitch()) {
    return useKillSwitch()
  }

  console.log('SW: Registering service worker')

  /*
  // Wait for window load event
  // Note: May omit as it probably breaks resources loading (lock in pending state) on M2M networks
  if (document.readyState === 'loading') { // loading | interactive | complete
    await new Promise(resolve => window.addEventListener('load', resolve, { once: true }))
  }
  */

  /** @throws {Error} - During service worker registration */
  const registration = await navigator.serviceWorker.register('service-worker.js')

  console.log('SW: Registration done')

  registration.addEventListener('updatefound', () => {
    const installingWorker = registration.installing
    if (installingWorker == null) {
      return
    }

    // Flow: 'installed' -> 'activating' -> 'activated'
    installingWorker.addEventListener('statechange', () => {
      if (installingWorker.state === 'installed') {
        if (navigator.serviceWorker.controller) {
          // At this point, the updated precached content has been fetched,
          // but the previous service worker will still server the older
          // content until all client tabs are closed.
          console.log('SW: New content is available.')

          // Execute callback
          if (config.onUpdate) {
            config.onUpdate(registration)
          }
        } else {
          // At this point, everyting has been precached.
          console.log('SW: Content is cached for offline use.')

          // Execute callback
          if (config.onSuccess) {
            config.onSuccess(registration)
          }
        }
      }
    })
  })

  return registration
}

/**
 * Unregister
 * @return {Promise<void>}
 */
export async function unregister() {
  if ('serviceWorker' in navigator === false) {
    return
  }

  // Get registrations across all windows
  const registrations = await navigator.serviceWorker.getRegistrations()

  await Promise.all(
    registrations.map(registration => registration.unregister())
  )
}

/**
 * Detect kill switch
 * @return {boolean}
 */
function detectForceKillSwitch() {
  // Detect kill switch
  const searchParams = new URLSearchParams(window.location.search)

  return searchParams.has(KILL_SWITCH_PARAM)
}

/**
 * Apply kill switch
 * @return {Promise<undefined, Error>}
 */
async function useKillSwitch() {
  console.log('Applying service worker kill-switch')

  // Unregister current one
  try {
    await unregister()
  } catch (error) {
    // noop
  }

  /** @throws {Error} */
  const killSwitchRegistration = await navigator.serviceWorker.register('service-worker-kill-switch.js')

  navigator.serviceWorker.addEventListener('controllerchange', async () => {
    // Unregister kill switch service worker
    try {
      /** @type {boolean} */
      await killSwitchRegistration.unregister()
    } catch (error) {
      // noop
    }

    console.log('Restarting in 15s...')

    window.setTimeout(
      () => {
        // Remove param from query
        const url = new URL(window.location.toString())

        url.searchParams.delete(KILL_SWITCH_PARAM)

        // Replace URL and reload without cache
        // Note: not using location.assign so it's possible to use hard reload later
        window.history.replaceState(null, window.document.title, url.toString())

        // @ts-ignore
        // See https://developer.mozilla.org/en-US/docs/Web/API/Location/reload#location.reload_has_no_parameter
        window.location.reload(true)
      },
      15 * SECONDS
    )
  })

  return
}
