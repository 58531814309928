import { html } from 'htm/preact'
import { useRef, useState, useEffect } from 'preact/hooks'

import FatalErrorComponent from '../../FatalError.js'

import { PREFETCH } from './Constants.js'

import './Video.css'

/**
 * @typedef { import('preact/hooks').Ref<HTMLVideoElement> } HTMLVideoElementRef
 * @typedef { import('preact/hooks').MutableRef<boolean> } BooleanRef
 * @typedef { import('preact/hooks').StateUpdater<string>} StringStateUpdater
 * @typedef { import('preact/hooks').StateUpdater<Error> } ErrorStateUpdater
 */

/**
 * @typedef {Object} ComponentConfig
 * @property {Cache} [cache]
 * @property {string} [prefetch] - One of: 'none'|'local-cache'|'network'
 * @property {boolean} [manualCacheRead] - Manually get video from cache (when not using Service workers)
 */

/**
 * @typedef {Object} ComponentProps
 * @property {BP.App.Renderer.Video.Options} options
 * @property {ComponentConfig} config
 * @property {number} [startAt]
 */

/**
 * Video component
 * @param {ComponentProps} props
 */
export default function Video({
  options = {
    url: undefined,
  },
  config = {
    cache: undefined,
    prefetch: PREFETCH.NONE,
    manualCacheRead: false,
  },
  startAt = 0
}) {
  /** @type {[string, StringStateUpdater]} - Video source */
  const [source, setSource] = useState(null)

  /** @type {[Error, ErrorStateUpdater]} */
  const [playError, setPlayError] = useState(null)

  /** @type {BooleanRef} - Pause ready flag, see https://goo.gl/LdLk22 */
  const pauseReadyRef = useRef(false)

  /** @type {HTMLVideoElementRef} */
  const videoRef = useRef(null)

  /**
   * Handle document visibility change
   * Note: Chrome handles video play/ pause on document visibilitychange by itself
   */
  useEffect(() => {
    window.document.addEventListener('visibilitychange', handleVisibilityChange, true)

    return () => window.document.removeEventListener('visibilitychange', handleVisibilityChange, true)

    function handleVisibilityChange() {
      const isDocumentHidden = window.document.hidden

      // Early exit
      if (
        videoRef.current === null ||
        videoRef.current.paused === isDocumentHidden ||
        pauseReadyRef.current === false
      ) {
        return
      }

      isDocumentHidden
        ? videoRef.current.pause()
        : videoRef.current.play()
    }
  }, [])

  /**
   * Handle source change
   */
  useEffect(() => {
    pauseReadyRef.current = false

    getSource(options.url)
      .then(setSource)

    return

    /**
     * Get source from cache if available, otherwise same URL
     * @param {string} url
     * @return {Promise<string>} - URL or object url
     * Note: Could set blob on srcObject but ATM not supported on Chromium v87
     * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/srcObject
     */
    async function getSource(url) {
      /** @type {Response|undefined} */
      let response

      switch (config.prefetch) {
        case PREFETCH.NETWORK:
          response = await window.fetch(createRequest(url)).catch(() => undefined)
          break

        case PREFETCH.LOCAL_CACHE:
          response = await config.cache.match(createRequest(url))
          break

        case PREFETCH.NONE:
        default:
          break
      }

      return response
        ? URL.createObjectURL(await response.blob())
        : url
    }
  }, [options.url])

  /**
   * Revoke previous source url on change and unmount
   */
  useEffect(() => {
    // Note: Cleanup refers to the previous value
    return () => source && revokeSourceUrl(source)
  }, [source])

  /**
   * Set current time
   */
  const handleVideoLoadedMetadata = () => {
    // Note: Doesn't count in time taken to load metadata/ prebuffer
    const offset = startAt % videoRef.current.duration

    if (offset !== videoRef.current.currentTime) {
      videoRef.current.currentTime = offset
    }
  }

  /**
   * Handle video play
   */
  const handleVideoPlay = () =>
    pauseReadyRef.current = true

  /**
   * Once video has ended, it's safe to cleanup
   */
  const handleVideoEnded = () =>
    revokeSourceUrl(source)

  /**
   * Handle video error
   * @param {Object} event
   * @param {HTMLVideoElement} event.target
   * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/error
   */
  const handleVideoError = ({ target }) =>
    setPlayError(new Error(`Cannot play video (${target.error.code}/ ${target.error.message}) `))

  // Show error
  if (playError) {
    return FatalErrorComponent({ items: [playError] })
  }

  return html`
    <div className="bip-container bip-renderer bip-renderer--video">
      <video
        className="bip-renderer--video__element"
        ref=${videoRef}
        autoplay
        loop
        muted
        playsinline
        preload="auto"
        controlslist="nodownload nofullscreen noremoteplayback"
        disablePictureInPicture
        disableRemotePlayback
        crossorigin="anonymous"
        src=${source}
        onLoadedmetadata=${handleVideoLoadedMetadata}
        onPlay=${handleVideoPlay}
        onEnded=${handleVideoEnded}
        onError=${handleVideoError}
      ></video>
    </div>
  `
}

/**
 * Populate cache
 * @access public
 * @param {ComponentConfig} config
 * @param {BP.App.Renderer.Video.Options} options
 * @param {AbortSignal} [abortSignal ]
 * @return {Promise<void>}
 */
Video.populateCache = async ({ cache }, options, abortSignal = undefined) => {
  const request = createRequest(options.url, undefined, abortSignal)

  if (await cache.match(request)) {
    return
  }

  return cache.add(request)
}

/**
 * Clean up cache
 * [NOT USED]
 * @access public
 * @param {ComponentConfig} config
 * @param {BP.App.Renderer.Video.Options} options
 * @param {AbortSignal} [abortSignal ]
 * @return {Promise<void>}
 */
Video.purgeCache = async ({ cache }, options, abortSignal = undefined) => {
  const request = createRequest(options.url, undefined, abortSignal)

  await cache.delete(request)
}

/**
 * Create request
 * @access protected
 * @param {string} url
 * @param {Headers} [headers]
 * @param {AbortSignal} signal
 * @return {Request}
 */
function createRequest(url, headers = undefined, signal = undefined) {
  return new Request(url, {
    method: 'GET',
    headers,
    signal,
  })
}

/**
 * Revoke sources' object URL
 * @param {string} source
 */
function revokeSourceUrl(source) {
  if (/^blob:.*$/.test(source)) {
    URL.revokeObjectURL(source)
  }
}
