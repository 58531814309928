import { html } from 'htm/preact'

import './Guidelines.css'

/**
 * Guidelines overlay
 * @param {Object} props
 * @param {boolean} props.enabled
 */
export default function Guidelines(
  props
) {
  return html`
  <div
    className="bip-guidelines"
    hidden=${!props.enabled}
  >
    <span className="bip-guideline bip-guideline-horizontal"></span>
    <span className="bip-guideline bip-guideline-vertical"></span>
    <div className="bip-guideline bip-guideline-markers"></div>
  </div>
`
}
