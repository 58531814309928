import { compile as pathCompile } from 'path-to-regexp'
import { openDB } from 'idb'

import { HOURS, MINUTES } from '../../../Constants/DateTime.js'

import {
  getCurrentNormValue,
  getCurrentNorm
} from './helpers.js'

/**
 * @typedef { import('idb').IDBPDatabase } IDBPDatabase
 * @typedef { import('path-to-regexp').PathFunction } PathFunction
 * @typedef { import('../../../Utils/Api').SyngeosApi } SyngeosApi
 */

/**
 * @typedef {Object} DataWrapper - Cache data wrapper object
 * @property {number} id
 * @property {number} receivedAt
 * @property {any} data
 */

/**
 * @typedef {Object} StationReading - API response reading
 * @property {number} id
 * @property {number} source
 * @property {string} address
 * @property {string} city
 * @property {[number, number]} coordinates
 * @property {boolean} send_reports
 * @property {{filename: string, url: string}} [first_logo]
 * @property {{filename: string, url: string}} [second_logo]
 * @property {StationSensor[]} [sensors]
 */

/**
 * @typedef {StationSensorBasic|StationSensorWithNorm} StationSensor
 */

/**
 * @typedef {Object} StationSensorBasic - Station sensor
 * @property {string} name
 * @property {string} unit
 * @property {string} display_type
 * @property {SensorDataBasic[]} data
 */

/**
 * @typedef {StationSensorBasic & TStationSensorWithNorm} StationSensorWithNorm - As above, but with norms
 * @typedef {Object} TStationSensorWithNorm
 * @property {SensorDataWithNorm[]} data
 * @property {{grade_a: object, grade_b: object, grade_c: object, grade_d: object, grade_e: object; grade_f: object, threshold: number}} norm - Threshold is an edge value when reading value will be over the norm
 */

/**
 * @typedef {SensorDataBasic | SensorDataWithNorm} SensorData
 */

/**
 * @typedef {Object} SensorDataBasic - Sensor data
 * @property {number} value
 * @property {string} read_at
 */

/**
 * @typedef {SensorDataBasic & TSensorDataWithNorm} SensorDataWithNorm - As above, but with norms
 * @typedef {Object} TSensorDataWithNorm
 * @property {SensorDataCurrentNorm} current_norm
 * @property {number} threshold_level - Norm in %
 */

/**
 * @typedef {'grade-a'|'grade-b'|'grade-c'|'grade-d'|'grade-e'|'grade-f'} SensorDataCurrentNorm
 */

/**
 * @typedef {Object} Readings - Modified readings
 * @property {string} label
 * @property {SensorDataCurrentNorm} currentNorm
 * @property {Array<{slug: string, label: string, unit: string, value: number | null, thresholdLevel: number | null, currentNorm: string | null}>} items
 */

/**
 * @typedef {Object} Config
 * @property {string} cacheDbName - Cache database name
 * @property {string[]} showSensorValues - Whitelisted sensor values, as defined in API
 * @property {Object<string, string>} customSensorLabels - Custom sensor labels
 * @property {EndpointConfig} sensors - Sensors list
 * @property {EndpointConfig} norms - Sensors norms
 * @property {EndpointConfig} reading - Readings
 */

/**
 * @typedef {Object} EndpointConfig
 * @property {string} endpoint
 * @property {number} interval
 */

/**
 * Syngeos API Service
 */
export default class Service {
  /**
   * Constructor
   * @param {SyngeosApi} syngeosApi
   * @param {Partial<Config>} config
   */
  constructor(syngeosApi, config) {
    /**
     * Syngeos API
     * @access protected
     * @type {SyngeosApi}
     */
    this.syngeosApi = syngeosApi

    /**
     * Cache database
     * @access protected
     * @type {IDBPDatabase|undefined}
     */
    this.cacheDb = undefined

    /**
     * Config
     * @access protected
     * @type {Config}
     */
    this.config = {
      cacheDbName: '@biotic/presenter-client/renderer/syngeos-station',
      showSensorValues: [
        'pm2_5',
        'pm10',
        'humidity',
        'air_pressure',
        'temperature',
        'co',
        'no2',
        'so2',
        'noise',
        'ch2o',
      ],
      customSensorLabels: {
        'pm2_5': 'PM2,5',
        'pm10': 'PM10',
        'humidity': 'Wilg.',
        'air_pressure': 'Ciśn',
        'temperature': 'Temp.',
        'co': 'CO',
        'no2': 'NO2',
        'so2': 'SO2',
        'noise': 'Hałas',
        'ch2o': 'CH2O',
      },
      sensors: {
        endpoint: 'public/sensors',
        interval: 24 * HOURS,
      },
      norms: {
        endpoint: 'public/norms',
        interval: 24 * HOURS,
      },
      reading: {
        endpoint: 'public/data/device/:id',
        interval: 10 * MINUTES,
      },
      ...config
    }

    /**
     * Compiled API endpoints
     * @access protected
     * @type {Object<string, PathFunction>}
     */
    this.endpointPathFn = {
      sensors: pathCompile(this.config.sensors.endpoint),
      norms:   pathCompile(this.config.norms.endpoint),
      reading: pathCompile(this.config.reading.endpoint),
    }
  }

  /**
   * Initialize async stuff
   * @access public
   * @return {Promise<this>}
   */
  async initialize() {
    this.cacheDb = await openDB(this.config.cacheDbName, 1, {
      upgrade(db) {
        db.createObjectStore('keyval', { keyPath: 'id' })
          .createIndex('receivedAt', 'receivedAt')

        db.createObjectStore('reading', { keyPath: 'id' })
          .createIndex('receivedAt', 'receivedAt')
      }
    })

    return this
  }

  /**
   * Get readings refresh interval
   * @access public
   * @return {Number}
   */
  getReadingsRefreshInterval() {
    return this.config.reading.interval
  }

  /**
   * Get reading from cache if not stale or fetch
   * @access public
   * @param {BP.App.Renderer.SyngeosStation.Options} stationOptions
   * @param {AbortSignal} [abortSignal]
   * @return {Promise<Readings,Error>}
   */
  async getReadings(stationOptions, abortSignal = undefined) {
    const data = await this.getCachedReadings(stationOptions)

    return data || this.fetchReadings(stationOptions, abortSignal)
  }

  /**
   * Get reading saving result in cache
   * @access protected
   * @param {BP.App.Renderer.SyngeosStation.Options} stationOptions
   * @param {AbortSignal} [abortSignal]
   * @return {Promise<Readings, Error>}
   * @throws {Error}
   */
  async fetchReadings(stationOptions, abortSignal = undefined) {
    /** @type {StationReading} */
    const data = await this.syngeosApi.get(
      this.endpointPathFn.reading({ id: stationOptions.id }),
      abortSignal
    )

    // Put in cache
    await this.cacheDb.put('reading', {
      id: stationOptions.id,
      data,
      receivedAt: Date.now(),
    })

    return this.decorateStationReading(data, stationOptions)
  }

  /**
   * Get cached measurement
   * @access public
   * @param {BP.App.Renderer.SyngeosStation.Options} stationOptions
   * @param {Boolean} [skipStaleCheck]
   * @param {Object} [data] - Fallback
   * @return {Promise<Readings|undefined>}
   */
   async getCachedReadings(stationOptions, skipStaleCheck = false, data = undefined) {
    // Load from cache
    /** @type {DataWrapper|undefined} */
    const dataWrapper = await this.cacheDb.get('reading', stationOptions.id)

    if (
      dataWrapper &&
      (skipStaleCheck || !Service.isStale(dataWrapper.receivedAt, this.config.reading.interval))
    ) {
      data = dataWrapper.data
    }

    // Decorate object
    return data
      ? this.decorateStationReading(data, stationOptions)
      : data
  }

  /**
   * Populate cache item
   * @access public
   * @param {{items: BP.App.Renderer.SyngeosStation.Options[]}} stationsOptions
   * @return {Promise<void, Error>}
   */
  async populateCacheItems(stationsOptions) {
    await Promise.all(stationsOptions.items.map(stationOptions =>
      this.getReadings(stationOptions)
    ))
  }

  /**
   * Populate cache items
   * @access public
   * @param {BP.App.Renderer.SyngeosStation.Options} stationOptions
   * @return {Promise<void, Error>}
   */
  async populateCacheItem(stationOptions) {
    await this.populateCacheItems({
      items: [stationOptions]
    })
  }

  /**
   * Clean up cache
   * @access public
   * @return {Promise<void>}
   */
  async purgeCache() {
    const { interval } = this.config.reading

    /** @type {DataWrapper[]} */
    const readings = await this.cacheDb.getAll('reading')

    const purgePromises = readings
      // Check if stale
      .filter(dataWrapper => Service.isStale(dataWrapper.receivedAt, interval))
      // Delete
      .map(dataWrapper => this.cacheDb.delete('reading', dataWrapper.id))

    await Promise.all(purgePromises)
  }

  /**
   * Check if data is stale
   * @access protected
   * @param {number} receivedAt
   * @param {number} maxAge
   * @return {boolean}
   */
  static isStale(receivedAt, maxAge) {
    return (
      maxAge !== Infinity &&
      Date.now() > receivedAt + maxAge
    )
  }

  /**
   * Decorate station reading
   * @access protected
   * @param {StationReading} stationReading
   * @param {BP.App.Renderer.SyngeosStation.Options} stationOptions
   * @return {Readings}
   */
  decorateStationReading(stationReading, stationOptions) {
    // Resolve label
    const label =
      stationOptions.label ||
      stationReading.address

    /** @type {number[]} */
    const normValues = []

    for (const stationSensor of stationReading.sensors || []) {
      const sensorData = stationSensor.data[0]

      if (sensorData && 'current_norm' in sensorData) {
        normValues.push(getCurrentNormValue(sensorData.current_norm))
      }
    }

    // Resolve worst norm
    const currentNorm = normValues.length ? getCurrentNorm(Math.max(...normValues)) : null

    // Use station options when provided and non-empty, otherwise fall back to service config
    const showSensorValues = stationOptions.sensors && stationOptions.sensors.length
      ? stationOptions.sensors
      : this.config.showSensorValues

    const items = (stationReading.sensors || [])
      // Keep only whitelisted sensors
      .filter(stationSensor =>
        showSensorValues.includes(stationSensor.name)
      )
      // Sort
      .sort((stationSensorA, stationSensorB) =>
        showSensorValues.indexOf(stationSensorA.name) -
        showSensorValues.indexOf(stationSensorB.name)
      )
      // Simplify
      .map(stationSensor => ({
        slug: stationSensor.name,
        label: this.config.customSensorLabels[stationSensor.name] || stationSensor.name,
        unit: stationSensor.unit,
        value: stationSensor.data.length
          ? stationSensor.data[0].value
          : null,
        thresholdLevel: stationSensor.data.length && 'threshold_level' in stationSensor.data[0]
          ? stationSensor.data[0].threshold_level
          : null,
        currentNorm: stationSensor.data.length && 'current_norm' in stationSensor.data[0]
          ? stationSensor.data[0].current_norm
          : null,
      }))

    return {
      label,
      currentNorm,
      items,
    }
  }
}
