import { Component, createRef } from 'preact'
import { html } from 'htm/preact'

import { Api } from '../../Utils/Api/index.js'
import { noop as onTaskNoop } from '../Progress.js'
import FatalErrorComponent from '../FatalError.js'

import './Image.css'

/**
 * @typedef { import('preact').RefObject<HTMLElement> } ContainerRef
 * @typedef { import('../../Utils/ClientHints').default } ClientHints
 */

/**
 * @typedef {Object} ComponentConfig
   @property {ClientHints} clientHints
 * @property {Cache} cache
 */

/**
 * @typedef {Object} ComponentProps
 * @property {BP.App.Renderer.Image.Options} options
 * @property {ComponentConfig} config
 * @property {BP.App.Tasks.handle} onTask
 */

/**
 * @typedef {Object} ComponentState
 * @property {string} [src]
 * @property {Error} [error]
 */

/**
 * Image renderer
 * @extends {Component<ComponentProps, ComponentState>}
 */
export default class Image extends Component {
  /**
   * @inheritdoc
   */
  static get defaultProps() {
    return {
      options: {
        url: undefined,
        fit: null,
        offset: null,
        bgColor: null,
      },
      config: {
        cache: undefined,
        clientHints: undefined,
      },
      onTask: onTaskNoop,
    }
  }

  /**
   * Allowed content types
   * @return {string[]}
   */
  static get allowedContentTypes() {
    return [
      'image/png',
      'image/jpeg',
      'image/gif',
      'image/webp',
      'image/svg+xml',
    ]
  }

  /**
   * Constructor
   * @param {ComponentProps} props
   */
  constructor(props) {
    super(props)

    /** @type {ComponentState} */
    this.state = {
      src: null,
      error: null,
    }

    /** @type {ContainerRef} */
    this.containerRef = createRef()

    /** @type {boolean} - Unmounting flag */
    this.isUnmounting = false

    /** @type {AbortController} */
    this.abortControllerImageFetch = null

    // Bind event listners
    this.handleImageLoad = this.handleImageLoad.bind(this)
    this.handleImageError = this.handleImageError.bind(this)
  }

  /**
   * @inheritdoc
   */
  async componentDidMount() {
    const updateTaskId = this.props.onTask()

    await this.updateImage(this.props.options.url)

    updateTaskId && this.props.onTask(updateTaskId)
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} prevProps
   */
  componentDidUpdate(prevProps) {
    if (prevProps.options.url !== this.props.options.url) {
      this.updateImage(this.props.options.url)
    }
  }

  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    this.isUnmounting = true

    Api.abort(this.abortControllerImageFetch)

    this.abortControllerImageFetch = null
  }

  /**
   * Handle image load
   * @param {Object} event
   * @param {HTMLImageElement} event.target
   */
  handleImageLoad(event) {
    // Clean up memory
    if (/^blob:.*$/.test(event.target.src)) {
      URL.revokeObjectURL(event.target.src)
    }
  }

  /**
   * Handle image load error
   * @note To test, set default state.src to ''
   * @note Event is deprecated on MDC
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Image_loading_errors
   */
  handleImageError() {
    this.setState({
      error: new Error('The image could not be loaded')
    })
  }

  /**
   * Update image
   * @param {string} url
   * @return {Promise<void>}
   */
  async updateImage(url) {
    // Abort previous controller
    Api.abort(this.abortControllerImageFetch)

    this.abortControllerImageFetch = new AbortController()

    /** @type {ComponentState} */
    const newState = {
      src: null,
      error: null,
    }

    const headers = this.props.config.clientHints.getHeaders(this.containerRef.current)
    const request = Image.createRequest(url, headers, this.abortControllerImageFetch.signal)

    try {
      const response = await fetch(request)
      const responseContentType = response.headers.get('Content-Type')

      // Validate content type
      // Note: don't check on opaque response as doesn't have access to headers
      if (!Image.allowedContentTypes.includes(responseContentType)) {
        throw new Error(`Invalid content type: ${responseContentType}`)
      }

      // Assign response body
      newState.src = URL.createObjectURL(await response.blob())
    } catch (error) {
      newState.error = new Error('Cannot load image')
    }

    this.abortControllerImageFetch = null

    if (this.isUnmounting) {
      return
    }

    this.setState(newState)
  }

  /**
   * @inheritdoc
   */
  render() {
    // Show error
    if (this.state.error) {
      return FatalErrorComponent({ items: [this.state.error]} )
    }

    // Let progress component handle it
    // Also prevent from firing error event on image with no src
    if (!this.state.src) {
      return null
    }

    return html`
      <div
        className="bip-container bip-renderer bip-renderer--image"
        ref=${this.containerRef}
        style=${{
          padding: this.props.options.offset ? `${this.props.options.offset}%` : undefined,
          backgroundColor: this.props.options.bgColor || undefined,
        }}
      >
        <img
          className=${`bip-renderer--image__element bip-image-fit--${this.props.options.fit || 'default'}`}
          alt=""
          decoding="sync"
          loading="eager"
          src=${this.state.src}
          crossorigin="anonymous"
          onLoad=${this.handleImageLoad}
          onError=${this.handleImageError}
        />
      </div>
    `
  }

  /**
   * Get image request info
   * @access protected
   * @param {string} url
   * @param {Headers} [headers]
   * @param {AbortSignal} [signal]
   * @return {Request}
   */
  static createRequest(url, headers = undefined, signal = undefined) {
    return new Request(url, {
      method: 'GET',
      headers,
      // mode: 'no-cors', // Note: no-cors doesn't send window.origin, cannot read response body and headers
      cache: 'default', // Interesting: 'no-store', 'reload' on precache; see https://developer.mozilla.org/en-US/docs/Web/API/Request/cache
      redirect: 'follow',
      // Note: Passing signal to request is undocumented on MDC
      signal,
    })
  }

  /**
   * Populate cache
   * @access public
   * @note Response may be a redirect
   * @param {ComponentConfig} config
   * @param {BP.App.Renderer.Image.Options} options
   * @param {AbortSignal} [abortSignal]
   * @return {Promise<void, Error>}
   */
  static async populateCache({ cache, clientHints }, options, abortSignal = undefined) {
    const request = Image.createRequest(
      options.url,
      clientHints.getHeaders(),
      abortSignal
    )

    // Do not overwrite existing response
    if (await cache.match(request)) {
      return
    }

    await cache.add(request)
  }

  /**
   * Clean up cache
   * @access public
   * @param {ComponentConfig} config
   * @param {BP.App.Renderer.Image.Options} options
   * @param {AbortSignal} [abortSignal]
   * @return {Promise<void>}
   */
  static async purgeCache({ cache, clientHints }, options, abortSignal = undefined) {
    await cache.delete(Image.createRequest(
      options.url,
      clientHints.getHeaders(),
      abortSignal
    ))
  }
}
