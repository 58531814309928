// In development mode
// import 'preact/debug'
import * as Sentry from '@sentry/browser'
import { render, createElement } from 'preact'

import App from './App.js'
import { withErrorBoundary } from './Components/SentryErrorBoundary.js'
import { withWakeLock } from './Components/WakeLock.js'
import ErrorContainer from './Containers/Error.js'

import './index.css'

// Note: do not check below as it's used by replace script
const env = window.APP_ENV
const packageMetadata = window.APP_PKG_META

// Stop when build data is not available
if (!env || !packageMetadata) {
  throw new Error('Failed to load build data')
}

// Load environment variables and package metadata
/** @type {BP.App.Options} */
const options = {
  ...env,
  package: packageMetadata
}

// Initialize Sentry
// See https://docs.sentry.io/error-reporting/configuration/?platform=javascript
Sentry.init({
  // Disable automatic session tracking
  autoSessionTracking: false,
  ...options.sentry,
  release: options.package.semver,
  // Note: may disable breadcrumb default integration, as it's not helpful at all
  // https://docs.sentry.io/platforms/javascript/integrations/default/#modifying-system-integrations
  // integrations: integrations => integrations.filter(integration => integration.name !== 'Breadcrumbs'),
  // Ignore `AbortError: The user aborted a request.`
  ignoreErrors: ['AbortError'],
})

const rootElement = document.getElementById('root')

/**
 * Clean up root element
 * Normally this would be handled by preact replacing containerNode children on render,
 * however doesn't for withErrorBoundary (on error thrown in component constructor)
 */
while (rootElement.lastElementChild) {
  rootElement.lastElementChild.remove()
}

// Render App
render(
  createElement(
    withErrorBoundary(
      withWakeLock(
        App
      ),
      { FallbackComponent: ErrorContainer }
    ),
    options
  ),
  // Note: Parents' child element is replaced only when created element tag name matches
  // @see https://github.com/preactjs/preact/issues/2004#issuecomment-573134053
  rootElement,
)


// Unmount
// In pracice for different origins pagehide/ unload fires only on reloads
// See https://developers.google.com/web/updates/2018/07/page-lifecycle-api
window.addEventListener('pagehide', () =>
  render(null, rootElement),
  false
)
