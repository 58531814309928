import { html } from 'htm/preact'

/**
 * Package info
 * @param {BP.App.PackageMetadata} props
 */
export default function Package(
  props
) {
  return html`
    <div className="bip-info">
      v${props.semver}
    </div>
  `
}
