import { Component } from 'preact'
import { html } from 'htm/preact'

import classNames from 'classnames'

import { compile as pathCompile } from 'path-to-regexp'

import * as Sentry from '@sentry/browser'

import MatomoTracker from '@datapunt/matomo-tracker-js'

import './App.css'

// Constants
import OVERLAY from './Constants/Overlay.js'
import { MINUTES, SECONDS } from './Constants/DateTime.js'

// Utils
import { Api, BiApi, SyngeosApi } from './Utils/Api/index.js'
import { openMain as openMainDb } from './Utils/db.js'
import uid from './Utils/uid.js'
import ClientHints from './Utils/ClientHints.js'
import { AppError } from './Utils/Error.js'
import {
  register as registerServiceWorker,
  unregister as unregisterServiceWorker
} from './serviceWorkerRegistration.js'
import forceSoftReload from './Utils/forceSoftReload.js'
import {
  updateScreenId,
  updateScreenConfig,
  getPlaylistFromDb,
  setPlaylistInDb,
  compare as compareState,
  compareUrlConfig,
} from './Utils/state/index.js'

// Toolbar components
import ToolbarComponent from './Components/Toolbar.js'
import GuidelinesComponent from './Components/Guidelines.js'
import SvgFilters from './Components/SvgFilters.js'

// Containers
// import Frame from './Components/Frame.js'
import PlaylistContainer from './Containers/Playlist.js'
import DashboardContainer from './Containers/Dashboard.js'
import ProgressComponent from './Containers/Progress.js'
import FatalErrorComponent from './Containers/FatalError.js'

/**
 * @typedef { import('preact').ErrorInfo } ErrorInfo
 * @typedef { import('idb').IDBPDatabase } IDBPDatabase
 * @typedef { import('path-to-regexp').PathFunction } PathFunction
 */

/**
 * @typedef {BP.App.Options} ComponentProps
 *
 * @typedef {Object} ComponentState
 * @property {string} screenId - Receiver screen ID
 * @property {BP.App.Api.Response.ScreenConfig} screenConfig - Screen config
 * @property {BP.App.Content.Playlist} playlist
 * @property {boolean} isDashboardOpened
 * @property {Array} overlays
 * @property {number[]} progressTasks - Progress indicator
 * @property {BP.App.ErrorWrapper[]} fatalErrors
 */

/** @type {BP.App.Content.Playlist} */
const initialPlaylistState = {
  items: [],
  screenSaverRange: null,
}

/**
 * App
 * @extends {Component<ComponentProps, ComponentState>}
 */
export default class App extends Component {
  /**
   * @inheritdoc
   * Note: Static props not supported in Buble
   *       The getDefaultProps method is available only in preact/compat https://github.com/preactjs/preact/issues/59#issuecomment-195783387
   * @return {ComponentProps}
   */
  static get defaultProps() {
    return {
      mode: undefined,
      debug: false,
      useServiceWorker: false,
      api: {
        token: undefined,
        baseUrl: undefined,
        paths: { screen: undefined },
        updateInterval: undefined,
      },
      runtimeCaching: {},
      matomo: {},
      sentry: {},
      package: {
        name: undefined,
        version: undefined,
        semver: undefined,
      },
    }
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} props
   */
  constructor(props) {
    super(props)

    /** @type {ComponentState} */
    this.state = {
      screenId: null,
      screenConfig: {
        resolution: { width: null, height: null },
        isLed: false,
      },
      playlist: initialPlaylistState,
      isDashboardOpened: App.isNavigationType('navigate'),
      overlays: [],
      progressTasks: [],
      fatalErrors: [],
    }

    /** @type {string} Start path */
    this.startPath = '/id/:screenId'

    /** @type {Api} */
    this.api = new Api(this.props.api, this.props.package)

    /** @type {BiApi} */
    this.biApi = new BiApi(this.props.api, this.props.package)

    /** @type {SyngeosApi} */
    this.syngeosApi = new SyngeosApi()

    /** @type {PathFunction} - Compiled API path */
    this.apiPathScrenFn = pathCompile(this.props.api.paths.screen)

    /** @type {number} */
    this.mountTaskId = null

    /** @type {IDBPDatabase} */
    this.db = null

    /** @type {ClientHints} */
    this.clientHints = new ClientHints({
      resolution: this.state.screenConfig.resolution,
      isLed: this.state.screenConfig.isLed,
    })

    /** @type {ServiceWorkerRegistration|null} */
    this.serviceWorkerRegistration = null

    /** @type {number|null} */
    this.updateServiceWorkerTimeoutId = null

    /** @type {Number|null} - API update interval */
    this.updateScreenFromApiIntervalId = null

    /** @type {Number|null} */
    this.dashboardHideTimeoutId = null

    /** @type {AbortController|null} */
    this.abortControllerApi = null

    /** @type {function} */
    this.unregisterForceSoftReload = undefined

    /** @type {MatomoTracker} */
    this.matomoTracker = new MatomoTracker(this.props.matomo)

    // Bind events
    this.handleTask = this.handleTask.bind(this)

    // Bind passed methods
    this.toggleOverlay = this.toggleOverlay.bind(this)

    // @ts-ignore
    window._DEBUG = this
  }

  /**
   * @inheritdoc
   * Note: takes about 30ms to boot with content type text
   */
  async componentDidMount() {
    this.mountTaskId = this.handleTask()

    this.db = await openMainDb(this.props.package.name, this.props.debug)

    // Initialize service worker
    if (this.props.useServiceWorker) {
      try {
        this.serviceWorkerRegistration = await registerServiceWorker({
          onSuccess: () => this.api.setPlatformDetail('sw', true),
          onUpdate: () => window.location.reload(),
        })
      } catch (error) {
        console.error('Error during service worker registration', error)

        if (
          /**
           * Ignore error while fetching service-worker.js
           * Failed to load resource: net::ERR_CONNECTION_RESET/ net::ERR_TIMED_OUT
           * `TypeError: Failed to register a ServiceWorker: An unknown error occurred when fetching the script.`
           */
          error instanceof TypeError === false &&
          /**
           * Ignore Huidu specific WebView errors - somehow service worker is still registered, at least on firmware 7.6.40.0
           * @see https://mswjs.io/docs/recipes/using-local-https
           * @see https://github.com/coder/code-server/issues/3410
           * `SecurityError: Failed to register a ServiceWorker for scope ('https://foobar.com/') with script ('https://foobar.com/service-worker.js'): An SSL certificate error occurred when fetching the script.`
           */
          /An SSL certificate error occurred when fetching the script\.$/.test(error.message) === false
        ) {
          Sentry.captureException(error)
        }
      }
    } else {
      unregisterServiceWorker()
    }

    if (this.serviceWorkerRegistration) {
      this.startPeriodicServiceWorkerRegistartionUpdate()
    }

    this.api.setPlatformDetail('sw', (this.serviceWorkerRegistration && this.serviceWorkerRegistration.active) !== null)

    // Handle screen config
    const screenConfig = await updateScreenConfig(window.location, this.db)

    // Read screen ID
    const screenId = await updateScreenId(window.location, this.startPath, this.db)

    // Indicate missing screen ID
    if (!screenId) {
      this.setStateAsync({ screenConfig })
      this.setFatalError(new AppError('Screen ID is missing'))

      this.mountTaskId = this.handleTask(this.mountTaskId)

      return
    }

    await this.setStateAsync({ screenId, screenConfig })

    // Hide dashboard later
    if (this.state.isDashboardOpened) {
      this.dashboardHideTimeoutId = window.setTimeout(
        () => this.setState({ isDashboardOpened: false }),
        10 * SECONDS
      )
    }

    document.documentElement.classList.toggle('bip-debug', this.props.debug)

    // Remove task from queue
    this.mountTaskId = this.handleTask(this.mountTaskId)

    // Start Matomo tracker
    this.matomoTracker.trackPageView({
      // See https://matomo.org/docs/custom-dimensions/
      // customDimensions: [{ id: 1, value: screenId }]
    })

    this.unregisterForceSoftReload = forceSoftReload()
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} prevProps
   * @param {ComponentState} prevState
   */
  async componentDidUpdate(prevProps, prevState) {
    // Debug mode
    if (this.props.debug !== prevProps.debug) {
      document.documentElement.classList.toggle('bip-debug', this.props.debug)
    }

    // Screen config
    if (!compareState(prevState.screenConfig, this.state.screenConfig)) {
      // Remove previous class names
      App.toggleHtmlClassNames(prevState.screenConfig, false)
      // Add new class names
      App.toggleHtmlClassNames(this.state.screenConfig, true)

      // Update client hints
      this.clientHints.updateState(this.state.screenConfig)
    }

    // Screen ID
    if (prevState.screenId !== this.state.screenId) {
      const screenIdUpdateTask = this.handleTask()

      // Update Sentry user
      Sentry.setUser({ id: this.state.screenId })

      // Reset playlist
      if (prevState.screenId) {
        await this.setStateAsync({ playlist: initialPlaylistState })
      }

      // Get cached playlist
      /** @type {BP.App.Content.Playlist|null} */
      const playlist = await getPlaylistFromDb(this.state.screenId, this.db)

      if (playlist) {
        await this.setStateAsync({ playlist })
      }

      // Stop periodic fetch
      this.stopPeriodicUpdateScreenFromApi()

      // Fetch playlist
      try {
        await this.updateScreenFromApi(1 * MINUTES)
      } catch (error) {
        // Indicate no connection + no empty cache
        if (!playlist) {
          this.setFatalError(error)
          return
        }
      } finally {
        // Start periodic fetch
        this.handleTask(screenIdUpdateTask)
      }

      this.startPeriodicUpdateScreenFromApi()
    }
  }

  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    // Stop updater
    this.stopPeriodicUpdateScreenFromApi()
    this.stopPeriodicServiceWorkerRegistrationUpdate()

    Api.abort(this.abortControllerApi)

    this.abortControllerApi = null

    if (this.dashboardHideTimeoutId) {
      window.clearTimeout(this.dashboardHideTimeoutId)
    }

    this.unregisterForceSoftReload && this.unregisterForceSoftReload()
  }

  /**
   * Catch errors thrown in descendant component
   * Note: mau remove as app is already wrapped in error boundary
   * @inheritdoc
   * @param {Error} error
   * @param {ErrorInfo} [errorInfo]
   */
  componentDidCatch(error, errorInfo) { // eslint-disable-line no-unused-vars
    // Halt app
    this.setFatalError(error)

    // Note: preact doesn't pass errorInfo
    // Use Sentry to log exception
    Sentry.captureException(error)
  }

  /**
   * Set up manual (explicit) service worker updates in 30min intervals
   * Required for long-opened SPAs
   * Note: Ansible is restarting browser (Ctrl+F5 in 1h interval)
   *       hovever Ctrl+F5 unlike F5 doesn't trigger service worker update
   * @access public
   * @param {number} [delay]
   * @param {number} [offset]
   */
  startPeriodicServiceWorkerRegistartionUpdate(delay = 30 * MINUTES, offset = 2 * MINUTES) {
    // Remaining time to delay (x:28, x:58)
    const timeout = (delay - Date.now() % delay) - offset

    this.updateServiceWorkerTimeoutId = window.setTimeout(
      () =>
        this.serviceWorkerRegistration.update()
          .catch(() => {})
          .finally(() => this.startPeriodicServiceWorkerRegistartionUpdate(delay))
      ,
      // Prevent negative timeouts to execute immediately
      Math.max(timeout, offset)
    )
  }

  /**
   * Stop service worker registration update loop
   * @access public
   */
  stopPeriodicServiceWorkerRegistrationUpdate() {
    if (this.updateServiceWorkerTimeoutId === null) {
      return
    }

    window.clearInterval(this.updateServiceWorkerTimeoutId)

    this.updateServiceWorkerTimeoutId = null
  }

  /**
   * Task handling
   * @access public
   * @param {number|null} oldTaskId - Old task to remove
   * @param {boolean} [replace] - Replace old task by created
   * @return {number|null}
   *
   * Usage:
   * ```js
   * // Add
   * this.taskId = props.handleTask(null)
   *
   * Replace
   * this.taskId  = props.handleTask(this.taskId, true)
   *
   * Remove
   * props.handleTask(this.taskId)
   * ```
   */
  handleTask(oldTaskId = null, replace = false) {
    let newTaskId = null
    let progressTasks = [...this.state.progressTasks]

    // Add
    if (!oldTaskId || replace) {
      newTaskId = uid()
      progressTasks.push(newTaskId)
    }

    // Remove
    if (oldTaskId) {
      progressTasks = progressTasks.filter(cmpUid => oldTaskId !== cmpUid)
    }

    this.setState({ progressTasks })

    return newTaskId
  }

  /**
   * Fetch and set
   * @access protected
   * @param {Number} [apiFetchTimeout]
   * @throws {Error}
   */
  async updateScreenFromApi(apiFetchTimeout = undefined) {
    /** @type {BP.App.Api.Response.Screen} */
    let data
    let apiFetchTimeoutId

    if (this.abortControllerApi) {
      Api.abort(this.abortControllerApi)
    }

    this.abortControllerApi = new AbortController()

    // Abort after timeout
    // For case when fetch takes forever when online, but net is down
    if (apiFetchTimeout) {
      apiFetchTimeoutId = window.setTimeout(
        () => this.abortControllerApi.abort(),
        apiFetchTimeout
      )
    }

    const apiPath = this.apiPathScrenFn({ screenId: this.state.screenId })

    try {
      data = await this.api.get(apiPath, this.abortControllerApi.signal)
    // eslint-disable-next-line no-useless-catch
    } catch (error) {
      // Rethrow
      throw error
    } finally {
      // Reset fetch timeout
      apiFetchTimeoutId && window.clearTimeout(apiFetchTimeoutId)

      // Reset abort controller
      this.abortControllerApi = null
    }

    const {
      config: screenConfig, // eslint-disable-line no-unused-vars
      playlist,
      appUrl
    } = data

    // Store in database cache
    await setPlaylistInDb(this.state.screenId, playlist, this.db)

    const configAppUrl = new URL(appUrl)

    // Navigate to App URL if different
    // Note: Strategy to update screenConfig state via URL
    if (
      // Debug flag
      !this.props.debug &&
      (
        // Origin
        configAppUrl.origin !== window.location.origin ||
        // Kill switch
        configAppUrl.searchParams.has('kill-switch') ||
        // Screen configuration
        !compareUrlConfig(configAppUrl, this.state.screenId, this.state.screenConfig, this.startPath)
      )
    ) {
      window.location.replace(appUrl)
      return
    }

    // No change in playlist
    if (compareState(this.state.playlist, playlist)) {
      return
    }

    return this.setStateAsync({ playlist })
  }

  /**
   * Start periodic fetch
   * @access public
   */
  startPeriodicUpdateScreenFromApi() {
    // Note: errors thrown inside interval function don't stop script execution
    this.updateScreenFromApiIntervalId = window.setInterval(
      () => this.updateScreenFromApi().catch(() => {}),
      this.props.api.updateInterval * SECONDS
    )
  }

  /**
   * Stop periodic fetch
   * @access public
   */
  stopPeriodicUpdateScreenFromApi() {
    if (this.updateScreenFromApiIntervalId === null) {
      return
    }

    window.clearInterval(this.updateScreenFromApiIntervalId)

    this.updateScreenFromApiIntervalId = null
  }

  /**
   * Set fatal error in stack unique by error name
   * @access protected
   * @param {Error} error
   * @param {string} [id]
   */
  setFatalError(error, id = error.name) {
    const fatalErrors = this.state.fatalErrors
      .filter(errorWrapper => errorWrapper.id !== id)
      .concat({ id, error })

    this.setState({ fatalErrors })
  }

  /**
   * Toggle overlay
   * @access public
   * @param {string} name
   */
  toggleOverlay(name) {
    this.setState({
      overlays: !this.state.overlays.includes(name)
        ? [...this.state.overlays, name]
        : this.state.overlays.filter(overlay => overlay !== name)
    })
  }

  /**
   * SetState but async
   * @access protected
   * @param {Object} state
   * @return {Promise<void>}
   */
  setStateAsync(state) {
    return new Promise(resolve =>
      this.setState(state, resolve)
    )
  }

  /**
   * @inheritdoc
   */
  render() {
    // Show errors
    if (this.state.fatalErrors.length) {
      return html`
        <${FatalErrorComponent}
          items=${this.state.fatalErrors.map(errorWrapper => errorWrapper.error)}
        />
      `
    }

    const screenConfig = this.state.screenConfig

    return html`
      <div
        id="bip-app"
        className=${classNames({
          'bip-overlay-pixelate': this.state.overlays.includes(OVERLAY.PIXELATE)
        })}
        style=${{
          width: screenConfig.resolution.width && `${screenConfig.resolution.width}px`,
          height: screenConfig.resolution.height && `${screenConfig.resolution.height}px`,
        }}
      >
        <!-- Toolbar -->
        <${ToolbarComponent}
          overlays=${this.state.overlays}
          package=${this.props.package}
          toggleOverlay=${this.toggleOverlay}
        />

        <!-- Guidelines -->
        <${GuidelinesComponent}
          enabled=${this.state.overlays.includes(OVERLAY.GUIDELINES)}
        />

        <!-- Progress indicator -->
        <${ProgressComponent}
          tasks=${this.state.progressTasks}
        />

        <!-- Dashboard -->
        ${this.state.isDashboardOpened && html`
          <${DashboardContainer}
            packageMetadata=${this.props.package}
            screenId=${this.state.screenId}
            screenConfig=${screenConfig}
          />
        `}

        <!-- Playlist -->
        <${PlaylistContainer}
          playlist=${this.state.playlist}
          runtimeCaching=${this.props.runtimeCaching}
          useServiceWorker=${this.props.useServiceWorker}
          biApi=${this.biApi}
          syngeosApi=${this.syngeosApi}
          clientHints=${this.clientHints}
          package=${this.props.package}
          handleTask=${this.handleTask}
        />

        <!-- SVG Filters -->
        <${SvgFilters} />
      </div>
    `
  }

  /**
   * Toggle html element class names
   * @access protected
   * @param {Object} screenConfig
   * @param {boolean} add
   */
  static toggleHtmlClassNames({ resolution, isLed = false }, add = undefined) {
    const resolutionClassName = `bip-calibrated-resolution--${resolution.width}x${resolution.height}`
    const isLedClassName = 'bip-screen--led'

    document.documentElement.classList.toggle(resolutionClassName, add)
    document.documentElement.classList.toggle(isLedClassName, isLed)
  }

  /**
   * Check navigation type
   * Note: this evals to true when replacing App URL
   * @param {string} type One of 'navigate'|'reload'|'back_forward'|'prerender'
   * @return {boolean}
   */
  static isNavigationType(type = 'navigate') {
    const [
      /** @type {PerformanceNavigationTiming} */
      performanceNavigationTiming
    ] = performance.getEntriesByType('navigation')

    // @ts-ignore
    return performanceNavigationTiming.type === type
  }
}
