/**
 * @see https://docs.sentry.io/platforms/javascript/react/
 * @see https://github.com/getsentry/sentry-javascript/tree/master/packages/react
 */

import { Component } from 'preact'
import { html } from 'htm/preact'

import * as Sentry from '@sentry/browser'

/**
 * @typedef { import('preact').ComponentClass } ComponentClass
 * @typedef { import('preact').FunctionComponent } FunctionComponent
 */

/**
 * @typedef {Object} ComponentProps
 * @property {preact.VNode[]} children
 * @property {function|null} FallbackComponent
 */

/**
 * Error boundary
 * @note Doesn't catch errors in async methods
 * @note When there is an error in child component before it renders (in constructor)
 *       Preact doesn't replace contents of parent container but prepends elements
 * @see https://reactjs.org/docs/error-boundaries.html
 * @extends {Component<ComponentProps>}
 */
export class SentryErrorBoundary extends Component {
  /**
   * @inheritdoc
   * @return {ComponentProps}
   */
  static get defaultProps() {
    return {
      FallbackComponent: null,
      children: null,
    }
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} props
   */
  constructor(props) {
    super(props)

    this.state = {
      /** @type {any} - Event ID used Sentry report dialog */
      eventId: null,
      /** @type {Error} */
      error: null,
    }
  }

  /**
   * @inheritdoc
   * @param {Error} error
   */
  static getDerivedStateFromError(error) {
    return { error }
  }

  /**
   * @inheritdoc
   * @param {Error} error
   * @param {Object} [errorInfo]
   */
  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)

      const eventId = Sentry.captureException(error)

      this.setState({ eventId })
    })
  }

  /**
   * Reset error boundary
   * @access public
   */
  resetErrorBoundary() {
    this.setState({
      eventId: null,
      error: null,
    })
  }

  /**
   * @inheritdoc
   */
  render() {
    const { children, FallbackComponent } = this.props
    const { error } = this.state

    // When there's not an error, render children untouched
    if (!error) {
      return children
    }

    // Use fallback component
    if (FallbackComponent) {
      return FallbackComponent({
        error,
        resetError: this.resetErrorBoundary,
      })
    }

    // Render fallback UI
    // Note: does nothing (at least when Sentry is disabled)
    return html`
      <article className="bip-container bip-fatal-error bip-fatal-error--fallback">
        <h1 className="bip-error-title">
          ${'Error'}
        </h1>
        <button
          className="bip-button"
          onClick=${() => Sentry.showReportDialog({ eventId: this.state.eventId })}
        >
          ${'Report feedback'}
        </button>
      </article>
    `
  }
}

/**
 * Error boundary Higher-Order Component
 * @see https://reactjs.org/docs/higher-order-components.html
 * @see https://github.com/bvaughn/react-error-boundary
 * @param {ComponentClass | FunctionComponent} WrappedComponent
 * @param {Object} [errorBoundaryProps]
 * @return {FunctionComponent}
 */
export function withErrorBoundary(
  WrappedComponent,
  errorBoundaryProps
) {
  // Format for display in DevTools
  const componentDisplayName = WrappedComponent.displayName || WrappedComponent.name || 'unknown'

  /**
   * @param {Object} props
   */
  const Wrapped = props => html`
    <${SentryErrorBoundary} ...${errorBoundaryProps}>
      <${WrappedComponent} ...${props} />
    </${SentryErrorBoundary}>
  `

  Wrapped.displayName = `withErrorBoundary(${componentDisplayName})`

  return Wrapped
}
