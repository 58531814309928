/**
 * @typedef { import('../db.js').IDBPDatabase } IDBPDatabase
 */

/**
 * Get playlist from cache
 * @param {string} screenId
 * @param {IDBPDatabase} db
 * @return {Promise<Object|null>}
 */
export async function getFromDb(screenId, db) {
  // const idBKeyRange = IDBKeyRange.only(screenId)
  // Note: IDBKeyRange may be used only on stores with keys
  const dataWrapper = await db.get('state', 'playlist')

  // No data
  if (!dataWrapper) {
    return null
  }

  // Different screen ID
  if (dataWrapper.screenId !== screenId) {
    return null
  }

  return dataWrapper.data
}

/**
 * Set playlist in cache
 * @param {string} screenId
 * @param {Object} playlist
 * @param {IDBPDatabase} db
 * @return {Promise<void, Error>}
 */
export async function setInDb(screenId, playlist, db) {
  await db.put('state', {
    screenId,
    data: playlist,
  }, 'playlist')
}
