import { html } from 'htm/preact'

/**
 * Fallback renderer (type not available)
 */
export default function Fallback() {
  return html`
    <article className="bip-renderer bip-renderer--text bip-container">
      <div className="bip-renderer--text__value">
        ${'Not Available'}
      </div>
    </article>
  `
}
