import { html } from 'htm/preact'
import classNames from 'classnames'

/**
 * Toggle Pixelate fx
 * @param {Object} props
 * @param {boolean} props.isActive
 * @param {function} props.toggleActive
 */
export default function Pixelate(props) {
  return html`
    <button
      className=${classNames('bip-button', { 'bip-button--active': props.isActive })}
      type="button"
      tabindex="-1"
      onClick=${props.toggleActive}
    >${'Pixelate'}</button>
  `
}
