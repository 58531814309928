/**
 * @typedef {Object} ClientHintsState
 * @property {boolean} isLed
 * @property {Object} resolution
 * @property {number|null} resolution.width
 * @property {number|null} resolution.height
 */

/**
 * Client hints helper which may be appended in fetch Headers or URLSearchParams
 * Consider: screenPixelDepth, navigator.hardwareConcurrency, storage.persisted
 * @see https://developers.google.com/web/fundamentals/performance/optimizing-content-efficiency/client-hints#client_hints_in_service_workers
 */
export default class ClientHints {
  /**
   * Constructor
   * @param {ClientHintsState} state
   */
  constructor({
    isLed = false,
    resolution = { width: null, height: null },
  }) {
    /** @type {ClientHintsState} */
    this.state = {
      isLed,
      resolution,
    }
  }

  /**
   * Update abilities
   * @access public
   * @param {ClientHintsState} state - Note: undefined means do not change
   */
  updateState({
    isLed = undefined,
    resolution = { width: undefined, height: undefined },
  }) {
    if (isLed !== undefined) {
      this.state.isLed = isLed
    }

    if (resolution.width !== undefined) {
      this.state.resolution.width = resolution.width
    }

    if (resolution.height !== undefined) {
      this.state.resolution.height = resolution.height
    }
  }

  /**
   * Get client hints
   * @access protected
   * @param {HTMLElement} [element]
   * @return {Object}
   */
  getObject(element = undefined) {

    const dpr = this.state.isLed
      ? .1
      : window.devicePixelRatio

    const width = element
      ? element.scrollWidth
      : this.state.resolution.width || screen.width


    return {
      // Image/ video quality
      'DPR': dpr.toString(),
      // 'Viewport-Width': (width * dpr).toString(),
      'Width': (width * dpr).toString(),

      /*
      // Network info
      // @ts-ignore
      'ECT': navigator.connection.effectiveType, // One of 'slow-2g'|'2g'|'3g'|'4g'
      // @ts-ignore
      'RTT': navigator.connection.rtt, // Approximate Round Trip Time
      // @ts-ignore
      'Save-Data': navigator.connection.saveData ? undefined : 'on',
      // @ts-ignore
      'Downlink': navigator.connection.downlink, // Bandwidth estimate in mb/s
      // @ts-ignore
      'Device-Memory': navigator.deviceMemory,
      */
    }
  }

  /**
   * Get headers
   * @access public
   * @param {HTMLElement} [element]
   * @return {Headers}
   */
  getHeaders(element = undefined) {
    return new Headers(this.getObject(element))
  }

  /**
   * Get query string
   * @access public
   * @param {HTMLElement} [element]
   * @return {URLSearchParams}
   */
  getSearchParams(element = undefined) {
    const object = this.getObject(element)

    // Translate keys
    return new URLSearchParams({
      'dpr': object['DPR'],
      'width': object['Width'],
    })
  }
}
