import { html } from 'htm/preact'

/**
 * Fatal error
 * Used by SentryErrorBoundary as FallbackComponent
 * @param {Object} props
 * @param {Error} props.error
 */
export default function Error({
  error
}) {
  return html`
    <article className="bip-container bip-fatal-error bip-fatal-error--error">
      <h1 className="bip-error-title">
        ${'Error'}
      </h1>
      <div>
        ${error.toString()}
      </div>
    </article>
  `
}
