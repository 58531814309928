import { html } from 'htm/preact'

import './Screensaver.css'

/**
 * Screen saver renderer
 */
export default function Screensaver() {
  return html`
    <article className="bip-container bip-renderer bip-renderer--screen-saver">
    </article>
  `
}
