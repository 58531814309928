import { Component } from 'preact'
import { html } from 'htm/preact'

import { noop as onTaskNoop } from '../Progress.js'
import { BiApi } from '../../Utils/Api/index.js'

import { getIndicatorClassName } from './BiStation/helpers.js'

import './Station/Station.css'
import './Station/indicator.css'
import './BiStation/indicator-aqi.css'

/**
 * @typedef { import('./BiStation/Service.js').default } Service
 * @typedef { import('./BiStation/Service.js').BiStationSensor } BiStationSensor
 * @typedef { import('./BiStation/Service.js').Measurement } Measurement
 */

/**
 * @typedef {Object} ComponentProps
 * @property {BP.App.Renderer.BiStation.Options} options
 * @property {{service: Service}} config
 * @property {BP.App.Tasks.handle} onTask
 */

/**
 * Beskid Instruments station renderer
 * @extends {Component<ComponentProps>}
 */
export default class BiStation extends Component {
  /**
   * @inheritdoc
   * @return {ComponentProps}
   */
  static get defaultProps() {
    return {
      options: {
        id: undefined,
        label: null,
      },
      config: { service: undefined },
      onTask: onTaskNoop,
    }
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} props
   */
  constructor(props) {
    super(props)

    this.state = {
      /** @type {BiStationSensor[]} */
      stationSensors: [],
      /** @type {Measurement|null} */
      measurement: null,
    }

    /** @type {Number|undefined} */
    this.refreshIntervalId = undefined

    /** @type {Map<string, AbortController>} */
    this.abortController = new Map()

    /** @type {Boolean} - Unmounting flag */
    this.isUnmounting = false
  }

  /**
   * @inheritdoc
   */
  async componentDidMount() {
    await this.updateDataFromCache()

    // Note: this may make screen blink
    // When loading data from cache (10ms - 30ms)
    const updateTaskId = !this.state.stationSensors.length
      ? this.props.onTask()
      : undefined

    await this.updateData()

    updateTaskId && this.props.onTask(updateTaskId)
  }

  /**
   * @inheritdoc
   * @param {ComponentProps} prevProps
   */
  async componentDidUpdate(prevProps) {
    if (
      prevProps.options.id !== this.props.options.id ||
      prevProps.options.label !== this.props.options.label
    ) {
      // Note: when component updates id, it shows previous data while fetching new ones
      await this.updateDataFromCache()
      await this.updateData()
    }
  }

  /**
   * @inheritdoc
   */
  componentWillUnmount() {
    this.refreshIntervalId && window.clearTimeout(this.refreshIntervalId)
    this.isUnmounting = true

    this.abortController.forEach(BiApi.abort)
    this.abortController.clear()
  }

  /**
   * Update data from cache with fall back to initial state
   * Note: 10 - 30ms
   * @access protected
   * @return {Promise<void>}
   */
  async updateDataFromCache() {
    const { service } = this.props.config

    /** @type {Object} */
    const newState = {}

    // Get cached sensors when empty
    if (!this.state.stationSensors.length) {
      newState.stationSensors = await service.getCachedStationSensors(true, [])
    }

    // Get cached measurement
    newState.measurement = await service.getCachedMeasurement(this.props.options, true, {})

    await new Promise(resolve => this.setState(newState, () => resolve()))
  }

  /**
   * Request data, update state and start timeout
   * @access protected
   * @return {Promise<void>}
   */
  async updateData() {
    const { service } = this.props.config

    /** @type {Object} */
    const newState = {}

    // Stop previous timeout
    this.refreshIntervalId && window.clearTimeout(this.refreshIntervalId)
    this.refreshIntervalId = undefined

    // Abort running requests
    this.abortController.forEach(BiApi.abort)
    this.abortController.clear()

    // Note: sequential promise resolution
    this.abortController
      .set('stationSensors', new AbortController())
      .set('measurements', new AbortController())

    try {
      [
        newState.stationSensors,
        newState.measurement,
      ] = await Promise.all([
        service.getStationSensors(this.abortController.get('stationSensors').signal),
        service.getMeasurement(this.props.options, this.abortController.get('measurements').signal),
      ])
    } catch (error) {
      // Noop
    } finally {
      this.abortController.delete('stationSensors')
      this.abortController.delete('measurement')
    }

    // Do not set state on unmount
    if (this.isUnmounting) {
      return
    }

    if (newState.stationSensors || newState.measurement) {
      await new Promise(resolve => this.setState(newState, () => resolve()))
    }

    // Update measurements in interval
    this.refreshIntervalId = window.setTimeout(
      () => this.updateData(),
      service.getMeasurementsRefreshInterval()
    )
  }

  /**
   * @inheritdoc
   */
  render() {
    // Handle data fetch error
    if (!this.state.stationSensors.length || !this.state.measurement) {
      return html`
        <article className="bip-container bip-renderer bip-renderer--bi-station">
          ${'No data'}
        </article>
      `
    }

    return html`
      <article className="bip-container bip-renderer bip-renderer--bi-station">
        <table className="bip-station-table">
          <thead>
            <!-- Caption -->
            <tr>
              <th
                className="bip-station-caption"
                colSpan=${this.state.stationSensors.length}
              >
                <!-- Label -->
                <span>
                  ${this.state.measurement.label || '-'}
                </span>
                <!-- Indicator -->
                <span className=${`station-indicator aqi-pl ${getIndicatorClassName(this.state.measurement.aqiPl)}`}></span>
              </th>
            </tr>
            <!-- Sensors (labels and units) -->
            <tr>
              ${this.state.stationSensors.map(stationSensor => html`
                <th className="bip-station-header">
                  <div className="bip-station-header__sensor-label">
                    ${stationSensor.label}
                  </div>
                  <div className="bip-station-header__sensor-unit">
                    ${stationSensor.unitSymbol}
                  </div>
                </th>
              `)}
            </tr>
          </thead>
          <!-- Values -->
          <tbody>
            <tr>
              ${this.state.stationSensors.map(stationSensor => html`
                <td className="bip-station-value">
                  ${(this.state.measurement.valuesMap.has(stationSensor.code) && this.state.measurement.valuesMap.get(stationSensor.code).value !== null)
                    ? this.state.measurement.valuesMap.get(stationSensor.code).value.toFixed(0)
                    : '-'
                  }
                </td>
              `)}
            </tr>
          </tbody>
        </table>
      </article>
    `
  }

  /**
   * Populate cache item
   * @access public
   * @param {ComponentProps['config']} config
   * @param {BP.App.Renderer.BiStation.Options} options
   * @return {Promise<void, Error>}
   */
  static async populateCache({ service }, options) {
    return service.populateCacheItem(options)
  }

  /**
   * Clean up cache
   * @access public
   * @param {ComponentProps['config']} config
   * @return {Promise<void>}
   */
  static async purgeCache({ service }) {
    return service.purgeCache()
  }
}
